import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Screen from "../../Components/Screen";
import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import { IStringObject } from "../../Interfaces/IStringObject";
import { toPrintableDateFormat } from "../../Util/TimeUtil";
import TernarySelector from "../../Components/TernarySelector";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import {
  OrganizationField,
  PublicCourseParticipantField,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import {
  CourseAttendanceLecture,
  CourseAttendanceParticipant,
  CourseAttendanceParticipantLecturesAttendingEnum as Attendence,
  PublicCourseAttendanceAttendenceEnum,
  PublicCourseAttendance,
} from "../../generated/Api";

export default function PublicCourseAttendancePage() {
  const { courseId } = useParams<{ courseId?: string }>();
  const dispatch = useDispatch();
  const { callApi, isLoading } = useVioletApi();

  const [courseName, setCourseName] = useState("");
  const [lectures, setLectures] = useState<CourseAttendanceLecture[]>([]);
  const [participantsAttendace, setParticipantsAttendace] = useState<
    CourseAttendanceParticipant[]
  >([]);

  useEffect(() => {
    if (!courseId) return;

    callApi((api) =>
      api.publicCourses
        .getPublicCourseParticipants({
          publicCourseId: parseInt(courseId),
        })
        .then((res) => {
          if (!res.data) {
            setLectures([]);
            setParticipantsAttendace([]);
            setCourseName("");
            return;
          }
          setCourseName(res.data.courseName);
          setLectures(res.data.lectures);
          setParticipantsAttendace(res.data.participantsAttendace);
        }),
    );
  }, [courseId, dispatch, callApi]);

  const tableHeaders: IStringObject[] = [
    {
      [PublicCourseParticipantField.firstName]: getFieldLabel(
        PublicCourseParticipantField.firstName,
      ),
    },
    {
      [PublicCourseParticipantField.lastName]: getFieldLabel(
        PublicCourseParticipantField.lastName,
      ),
    },
    { organizationName: getFieldLabel(OrganizationField.name) },
  ];
  lectures.forEach((lecture) =>
    tableHeaders.push({
      [lecture.lectureId]: getLectureTitle(lecture),
    }),
  );

  const onChecked = async (
    participantAttendace: CourseAttendanceParticipant,
    lectureId: string,
    attended: boolean | undefined,
  ) => {
    const attendence =
      attended === true
        ? Attendence.ATTENDED
        : attended === undefined
          ? Attendence.UNSET
          : Attendence.NOT_ATTENDED;
    const markAttendence =
      attended === true
        ? PublicCourseAttendanceAttendenceEnum.ATTENDED
        : attended === undefined
          ? PublicCourseAttendanceAttendenceEnum.UNSET
          : PublicCourseAttendanceAttendenceEnum.NOT_ATTENDED;
    setParticipantsAttendace((attendance) => {
      const res = [...attendance];
      const courseAttendanceParticipant = res.filter(
        (x) => x.participantId === participantAttendace.participantId,
      )[0];
      courseAttendanceParticipant.lecturesAttending[lectureId] = attendence;

      return res;
    });
    callApi(async (api) => {
      const request: PublicCourseAttendance = {
        publicCourseParticipantId: participantAttendace.participantId,
        publicCourseLectureId: parseInt(lectureId),
        attendence: markAttendence,
      };
      api.publicCourses.markAttendance(request);
    });
  };

  return (
    <Screen fullWidth isLoading={lectures.length === 0 && isLoading}>
      <CustomPaperTable
        title={"דף נוכחות בקורס - " + courseName}
        elements={participantsAttendace}
        tableHeaders={tableHeaders}
        onEditButton={() => {}}
        renderCell={(headerKey, participantAttendace) => {
          switch (headerKey) {
            case PublicCourseParticipantField.firstName:
            case PublicCourseParticipantField.lastName:
            case "organizationName":
              return null;
          }

          const lectureAttendence =
            participantAttendace.lecturesAttending[headerKey];
          if (lectureAttendence === undefined) {
            // Participant is not attending this lecture.
            return <div />;
          }
          const attendeing =
            lectureAttendence === Attendence.ATTENDED
              ? true
              : lectureAttendence === Attendence.UNSET
                ? undefined
                : false;
          return (
            <TernarySelector
              onChange={(checked) =>
                onChecked(participantAttendace, headerKey, checked)
              }
              value={attendeing}
            />
          );
        }}
      />
    </Screen>
  );
}

export function getLectureTitle(lecture: CourseAttendanceLecture): string {
  if (!lecture.date) {
    return "" + lecture.topic;
  }
  return toPrintableDateFormat(lecture.date) + "\n" + lecture.topic;
}
