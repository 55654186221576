import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import Screen from "../../../Components/Screen";
import CourseParticipantsTable from "./Sections/CourseParticipantsTable";
import CustomPaperTable from "../../../Components/Table/CustomPaperTable";
import { Path } from "../../Path";
import PublicCourseSummaryPageSideMenu, {
  PublicCourseDetails,
} from "./PublicCoursePageSideMenu";
import { useVioletApi } from "../../../Auth/useFetchWithMsal";
import { CustomIconButton } from "../../../Components/CustomComponents/CustomButtons";
import EditIcon from "@mui/icons-material/Edit";
import MobileViewContext from "../../../Navigation/MobileViewContext";
import CustomSection from "../../../Components/CustomComponents/CustomSection";
import {
  PublicCourse,
  PublicCourseLecture,
  PublicCourseLectureSummary,
  PublicCourseParticipantSummary,
} from "../../../generated/Api";

export default function PublicCourseSummaryPage() {
  const [mobileView] = useContext(MobileViewContext);
  const { courseId } = useParams<{ courseId?: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { callApi, isLoading } = useVioletApi();

  const [publicCourse, setPublicCourse] = useState<PublicCourse>(
    getEmptyPublicCourse(),
  );
  const [lecturesSummary, setLecturesSummary] = useState<
    PublicCourseLectureSummary[]
  >([]);
  const [participantsSummary, setParticipantsSummary] = useState<
    PublicCourseParticipantSummary[]
  >([]);
  const [courseSum, setCourseSum] = useState<string>("");
  const [isoFee, setIsoFee] = useState<string>("");

  useEffect(() => {
    if (!courseId) return;
    callApi((api) =>
      api.publicCourses
        .getPublicCourse({ publicCourseId: parseInt(courseId) })
        .then((res) => {
          if (!res.data) {
            setPublicCourse(getEmptyPublicCourse());
            setLecturesSummary([]);
            setParticipantsSummary([]);
            setCourseSum("");
            setIsoFee("");
            return;
          }
          setPublicCourse(res.data.publicCourse);
          setLecturesSummary(res.data.lecturesSummary);
          setParticipantsSummary(res.data.participantsSummary);
          setCourseSum(res.data.courseSum);
          setIsoFee(res.data.isoFee);
        }),
    );
  }, [courseId, dispatch, callApi]);

  const onEditLectures = () => navigate(Path.EditPublicCourse + "/" + courseId);

  return (
    <Screen
      isLoading={isLoading}
      sideMenu={
        <PublicCourseSummaryPageSideMenu
          publicCourse={publicCourse}
          courseSum={courseSum}
          isoFee={isoFee}
        />
      }
    >
      {mobileView && (
        <CustomSection title={publicCourse.courseName}>
          <PublicCourseDetails publicCourse={publicCourse} />
        </CustomSection>
      )}
      <CourseParticipantsTable
        participantsSummary={participantsSummary}
        lecturesSummary={lecturesSummary}
        approved
      />

      <CourseParticipantsTable
        participantsSummary={participantsSummary}
        lecturesSummary={lecturesSummary}
        approved={false}
      />

      <CustomPaperTable
        title={"הרצאות"}
        elements={lecturesSummary}
        tableHeaders={[
          { date: "תאריך" },
          { topic: "נושא" },
          { startTime: "התחלה" },
          { endTime: "סיום" },
          { price: "מחיר השתתפות" },
          { participantCount: "מספר משתתפים" },
          { income: "הכנסות" },
        ]}
        onEditButton={onEditLectures}
        sectionOptions={
          <CustomIconButton onClick={onEditLectures} tooltip="עריכת הרצאות">
            <EditIcon />
          </CustomIconButton>
        }
      />
    </Screen>
  );
}

const getEmptyPublicCourse = () =>
  ({
    publicCourseLectures: [] as PublicCourseLecture[],
  }) as PublicCourse;
