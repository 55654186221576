import classNames from "classnames";
import React, { useContext } from "react";
import MobileViewContext from "../../Navigation/MobileViewContext";
import CustomPaper from "./CustomPaper";
import styles from "./CustomSection.module.scss";
import { CircularProgress } from "@mui/material";

interface CustomSectionProps {
  title?: string;
  children?: React.ReactNode;
  options?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
}

export default function CustomSection(props: CustomSectionProps) {
  const [mobile] = useContext(MobileViewContext);
  return (
    <CustomPaper className={classNames(styles.section, props.className)}>
      {props.title && (
        <div className={styles.title}>
          <div className={styles.titleText}>{props.title}</div>
          {!mobile && props.options}
        </div>
      )}
      <div
        className={classNames({
          [styles.paperContent]: true,
          [styles.mobilePaper]: mobile,
        })}
      >
        {props.isLoading ? (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        ) : (
          props.children
        )}
      </div>
    </CustomPaper>
  );
}
