import styles from "./OrderPageSideMenu.module.css";
import SendIcon from "@mui/icons-material/Mail";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Alert, Divider } from "@mui/material";
import OrderApprovalBox from "./OrderApprovalBox";
import FollowUpSection from "./Sections/FollowUp/FollowUpSection";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import SideMenu from "../../Components/SideMenu";
import {
  Field,
  getFieldLabel,
  OrderField,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { OnOrderChangeFunction } from "./OrderPage";
import SaveIcon from "@mui/icons-material/Save";
import ChangeTimes from "../../Components/ChangeTimes";
import { isCriticalCase, toActionRequiredCase } from "../ActionRequiredPage";
import { getOrderStatusLabel } from "../../Interfaces/Status";
import _ from "lodash";
import { hasDatePassed } from "../../Util/TimeUtil";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Order,
  OrderLecture,
  Organization,
  PublicCourse,
  PublicCourseParticipant,
} from "../../generated/Api";

interface OrderPageSideMenuProps {
  organization: Organization;
  order: Order;
  orderLectures: OrderLecture[];
  publicCourse: PublicCourse;
  publicCourseParticipants: PublicCourseParticipant[];
  onOrderChange: OnOrderChangeFunction;
  onSaveOrder: () => void;
  onDuplicateOrder: () => void;
  onDeleteOrder: () => void;
  requiredFields: Field[];
  orderHasUnsavedChanges: boolean;
}

export default function OrderPageSideMenu(props: OrderPageSideMenuProps) {
  const allowDeletion =
    props.orderLectures.length === 0 &&
    props.publicCourseParticipants.length === 0 &&
    !props.orderHasUnsavedChanges;
  return (
    <SideMenu title={getFieldLabel(OrderField.id) + " " + props.order.id}>
      <OrderStatus
        order={props.order}
        orderLectures={props.orderLectures}
        publicCourse={props.publicCourse}
      />
      <MenuDivider />

      <OrderApprovalBox
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
      />
      <MenuDivider />

      <FollowUpSection
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
      />
      <MenuDivider />

      <ChangeTimes
        changedDate={props.order.changedDate}
        createdDate={props.order.createdDate}
      />
      <MenuDivider />

      <CustomRaisedButton
        onClick={props.onSaveOrder}
        label="שמור"
        icon={<SaveIcon />}
        variant={props.orderHasUnsavedChanges ? "contained" : "outlined"}
        fullWidth
      />

      <br />
      <br />

      <EmailCustomerButton
        organization={props.organization}
        order={props.order}
      />

      <br />
      <br />
      <ButtonGroup fullWidth>
        <CustomRaisedButton
          label="שכפל"
          icon={<FileCopyIcon />}
          onClick={props.onDuplicateOrder}
          disabled={props.orderHasUnsavedChanges}
        />
        <CustomRaisedButton
          label="מחק"
          icon={<ClearIcon />}
          onClick={props.onDeleteOrder}
          disabled={!allowDeletion}
        />
      </ButtonGroup>
    </SideMenu>
  );
}

function OrderStatus(props: {
  order: Order;
  orderLectures: OrderLecture[];
  publicCourse: PublicCourse;
}) {
  const statusLabel = getOrderStatusLabel(props.order);

  const actionRequiredCase = props.order.alertCase;
  const showAlert =
    !!actionRequiredCase &&
    props.order.alertStartTime &&
    hasDatePassed(props.order.alertStartTime);

  let severity: "error" | "warning" = "error";
  if (
    actionRequiredCase &&
    !isCriticalCase(toActionRequiredCase(actionRequiredCase))
  ) {
    severity = "warning";
  }
  return (
    <div>
      <div className={styles.status}>סטאטוס: {statusLabel}</div>
      <br />

      {showAlert && (
        <Alert severity={severity}>{props.order.alertMessage}</Alert>
      )}
    </div>
  );
}

function EmailCustomerButton(props: {
  organization: Organization;
  order: Order;
}) {
  const contactId = props.order.contactId;
  const contact =
    !contactId || contactId <= 0
      ? undefined
      : _.find(props.organization.contacts, (c) => c.id === contactId);

  let href = "";
  if (contact) {
    href += "mailto:";
    href += contact.email;
    href += "?subject=חנן מלין - הצעת מחיר מספר " + props.order.id;
  }

  return (
    <CustomRaisedButton
      label="שלח"
      icon={<SendIcon />}
      onClick={() => window.open(href)}
      variant="outlined"
      fullWidth
      disabled={!contact}
    />
  );
}

function MenuDivider() {
  return (
    <div className={styles.divider}>
      <Divider />
    </div>
  );
}
