import _ from "lodash";
import CourseLecturesInstance from "./CourseLecturesInstance";
import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import { CustomRaisedButton } from "../../../../Components/CustomComponents/CustomButtons";
import AddIcon from "@mui/icons-material/Add";
import { PublicCourseLectureField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { calculateDuration } from "../../../../Util/TimeUtil";
import { PublicCourse, PublicCourseLecture } from "../../../../generated/Api";

export default function CourseLecturesSection(props: {
  publicCourse: PublicCourse;
  participantCount: { [lectureId: number]: number };
  setPublicCourse: React.Dispatch<React.SetStateAction<PublicCourse>>;
  requiredFields: PublicCourseLectureField[];
}) {
  const setPublicCourseLectures = (
    lecturesUpdate: (
      lecturesBefore: PublicCourseLecture[],
    ) => PublicCourseLecture[],
  ) => {
    props.setPublicCourse((publicCourse) => ({
      ...publicCourse,
      publicCourseLectures: lecturesUpdate(publicCourse.publicCourseLectures),
    }));
  };

  const addLectureTime = () => {
    setPublicCourseLectures((lectures) => [
      ...lectures,
      {} as PublicCourseLecture,
    ]);
  };

  const onDelete = (rowIndex: number) => {
    setPublicCourseLectures((lectures) =>
      lectures.filter((_, index) => index !== rowIndex),
    );
  };

  const onFieldChange =
    (lectureIndex: number) =>
    (key: PublicCourseLectureField) =>
    (value: string | boolean) => {
      setPublicCourseLectures((originalLectures) => {
        const lectures = _.clone(originalLectures);
        const lectureTime = lectures[lectureIndex];
        // @ts-ignore
        lectureTime[key] = value;
        lectureTime.duration = calculateDuration(lectureTime);
        return lectures;
      });
    };

  return (
    <CustomSection title="הרצאות">
      <CustomRaisedButton
        label="הוסף הרצאה"
        icon={<AddIcon />}
        onClick={addLectureTime}
        variant="outlined"
        style={{ marginBottom: 20 }}
      />

      {_.map(props.publicCourse.publicCourseLectures, (lecture, index) => (
        <CourseLecturesInstance
          key={lecture.id + ":" + index}
          lecture={lecture}
          onFieldChange={onFieldChange(index)}
          deletable={props.participantCount[lecture.id] <= 0}
          onDelete={() => onDelete(index)}
          index={index}
          isOnlineCourse={props.publicCourse.onlineCourse}
          requiredFields={props.requiredFields}
        />
      ))}
    </CustomSection>
  );
}
