import { useEffect, useState } from "react";
import Screen from "../../Components/Screen";
import SideMenu from "../../Components/SideMenu";
import SelectField from "../../Components/SelectField";
import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import { getOrderPath } from "../Path";
import { useNavigate } from "react-router";
import { getStatusOptions } from "../../Interfaces/Status";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import {
  GetAllOrdersParamsStatusEnum,
  OrderSummary,
} from "../../generated/Api";

export default function AllOrdersPage() {
  const navigate = useNavigate();
  const [ordersSummary, setOrdersSummary] = useState<OrderSummary[]>([]);
  const [filterStatus, setFilterStatus] = useState<
    GetAllOrdersParamsStatusEnum | undefined
  >();
  const { callApi, isLoading } = useVioletApi();

  useEffect(() => {
    callApi((api) =>
      api.orders.getAllOrders({ status: filterStatus }).then((res) => {
        if (res.data) {
          setOrdersSummary(res.data);
        }
      }),
    );
  }, [filterStatus, callApi]);

  const filterStatusSelectField = (
    <SideMenu title="סינון הזמנות לפי סטאטוס:">
      <SelectField
        label="סטאטוס"
        value={filterStatus}
        onChange={(newStatusString) =>
          setFilterStatus(newStatusString as GetAllOrdersParamsStatusEnum)
        }
        options={getStatusOptions()}
        allowEmpty
        fullWidth
      />
    </SideMenu>
  );

  return (
    <Screen sideMenu={filterStatusSelectField} isLoading={isLoading}>
      <CustomPaperTable
        title="כל ההזמנות"
        elements={ordersSummary}
        tableHeaders={[
          { id: "מספר הזמנה" },
          { organizationName: "שם הארגון" },
          { status: "סטאטוס" },
          { topic: "נושא" },
          { lectureDate: "תאריך הרצאה" },
          { lecturesCount: "מספר הרצאות" },
        ]}
        onEditButton={(summary) => {
          navigate(getOrderPath(summary.id));
        }}
      />
    </Screen>
  );
}
