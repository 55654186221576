import * as _ from "lodash";
import { Order, OrderStatusEnum } from "../../../generated/Api";

export function existsAndNotEmpty(order: Order, key: string) {
  // @ts-ignore
  return _.has(order, key) && order[key] && isNonEmptyArray(order[key]);
}

function isNonEmptyArray(arr: any[]) {
  return _.isArray(arr) ? arr.length !== 0 : true;
}

export function isMatchingStatus(
  order: Order,
  status: OrderStatusEnum | OrderStatusEnum[],
) {
  if (_.isArray(status)) return _.includes(status, order.status);

  return order.status === status;
}
