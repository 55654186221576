import Routes from "./Routes";
import Store from "./Store";
import Auth from "./Auth/Auth";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Auth>
    <Provider store={Store}>
      <Routes />
    </Provider>
  </Auth>,
);
