import React from "react";
import Divider from "@mui/material/Divider";

export default function CustomDivider(props: {
  style?: React.CSSProperties;
  orientation?: "horizontal" | "vertical";
}) {
  const style = {
    marginTop: 10,
    marginBottom: 10,
    ...props.style,
  };

  return <Divider style={style} orientation={props.orientation} />;
}
