import Switch from "@mui/material/Switch";
import Colors from "../../Util/Constants/Colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Field,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { Size } from "../../Util/Constants/Size";
import { shouldMarkWithError } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";

interface CustomToggleProps {
  field: Field;
  valueObject: any;
  requiredFields?: Field[];
  fullWidth?: boolean;
  size?: Size;
  onChange: (newValue: boolean) => void;
}

export default function CustomToggle(props: CustomToggleProps) {
  const value = props.valueObject[props.field] === true;
  const error = shouldMarkWithError(props.field, props.requiredFields, value);

  const labelStyle = {
    color: error ? Colors.red : Colors.black,
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={(_, checked) => props.onChange(checked)}
            color="primary"
          />
        }
        label={<span style={labelStyle}>{getFieldLabel(props.field)}</span>}
      />
    </div>
  );
}
