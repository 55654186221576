import React from "react";

/* Whether the UI should be mobile friendly. */
export default React.createContext<[boolean, (mobileView: boolean) => void]>([
  false,
  (mobileView: boolean) => {},
]);

export function isMobileView() {
  return window.innerWidth <= 700;
}

export function isMobileScreen() {
  return window.innerWidth < 1300;
}
