import styles from "./SmallScreenMenu.module.scss";
import MenuItems from "./MenuItems";
import { Dispatch, SetStateAction, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Colors from "../Util/Constants/Colors";

export default function SmallScreenMenu() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const appTitle = "שיא האיכות - Violet";

  return (
    <AppBar position="static">
      <VioletToolbar setDrawerOpen={setDrawerOpen} appTitle={appTitle} />

      <Drawer
        anchor={"left"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: Colors.primary,
          },
        }}
      >
        <DrawerToolbar setDrawerOpen={setDrawerOpen} />
        <MenuItems onItemClick={() => setDrawerOpen(false)} />
      </Drawer>
    </AppBar>
  );
}

function VioletToolbar(props: {
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  appTitle: string;
}) {
  return (
    <Toolbar>
      <IconButton
        size="small"
        edge="start"
        color="inherit"
        sx={{ mr: 2 }}
        onClick={() => props.setDrawerOpen((open: boolean) => !open)}
      >
        <MenuIcon />
      </IconButton>
      <div className={styles.appNameButton}>{props.appTitle}</div>
    </Toolbar>
  );
}

function DrawerToolbar(props: {
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Toolbar>
      <IconButton
        size="large"
        edge="start"
        color="default"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={() => props.setDrawerOpen((open) => !open)}
      >
        <KeyboardArrowRightIcon className={styles.backIcon} />
      </IconButton>
    </Toolbar>
  );
}
