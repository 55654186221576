import { Divider } from "@mui/material";
import SideMenu from "../../../Components/SideMenu";
import ChangeTimes from "../../../Components/ChangeTimes";
import {
  getFieldLabel,
  PublicCourseField,
} from "../../../Store/Appearance/RequiredFields/FieldNames";
import ButtonGroup from "@mui/material/ButtonGroup";
import { CustomRaisedButton } from "../../../Components/CustomComponents/CustomButtons";
import { useNavigate } from "react-router";
import { Path } from "../../Path";
import EditIcon from "@mui/icons-material/Edit";
import RuleIcon from "@mui/icons-material/Rule";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import GradingIcon from "@mui/icons-material/Grading";
import { useContext, useState } from "react";
import MobileViewContext from "../../../Navigation/MobileViewContext";
import CopyEmailsDialog from "./CopyEmailsDialog";
import { PublicCourse } from "../../../generated/Api";

interface PublicCourseSummaryPageSideMenuProps {
  publicCourse?: PublicCourse;
  courseSum: string;
  isoFee: string;
}

export default function PublicCourseSummaryPageSideMenu(
  props: PublicCourseSummaryPageSideMenuProps,
) {
  const navigate = useNavigate();
  const [copyEmailDialogOpen, setCopyEmailDialogOpen] = useState(false);

  if (!props.publicCourse || !props.publicCourse.createdDate) {
    return null;
  }

  return (
    <SideMenu title={"קורס ציבורי: " + props.publicCourse.courseName}>
      הכנסות (ללא מע"מ): {props.courseSum}
      <MenuDivider />
      <CourseProperty
        field={PublicCourseField.onlineCourse}
        value={props.publicCourse.onlineCourse ? "מקוון" : "פרונטלי"}
      />
      <CourseProperty field={PublicCourseField.isoFee} value={props.isoFee} />
      <CourseProperty
        field={PublicCourseField.isoPayed}
        value={props.publicCourse.isoPayed ? "כן" : "לא"}
      />
      <PublicCourseDetails publicCourse={props.publicCourse} />
      <MenuDivider />
      <ChangeTimes
        changedDate={props.publicCourse.changedDate}
        createdDate={props.publicCourse.createdDate}
      />
      <MenuDivider />
      <ButtonGroup fullWidth>
        <CustomRaisedButton
          label="ערוך"
          fullWidth
          variant={"outlined"}
          icon={<EditIcon />}
          onClick={() =>
            navigate(Path.EditPublicCourse + "/" + props.publicCourse?.id)
          }
        />
        <CustomRaisedButton
          label="העתק מיילים"
          fullWidth
          variant={"outlined"}
          icon={<ContactMailIcon />}
          onClick={() => setCopyEmailDialogOpen(true)}
        />
      </ButtonGroup>
      <br />
      <br />
      <ButtonGroup fullWidth>
        <CustomRaisedButton
          label="דף נוכחות"
          fullWidth
          variant={"outlined"}
          icon={<RuleIcon />}
          onClick={() =>
            navigate(Path.PublicCourseAttendance + "/" + props.publicCourse?.id)
          }
        />
        <CustomRaisedButton
          label="ציונים"
          fullWidth
          variant={"outlined"}
          icon={<GradingIcon />}
          onClick={() =>
            navigate(Path.PublicCourseTestScores + "/" + props.publicCourse?.id)
          }
        />
      </ButtonGroup>
      <CopyEmailsDialog
        open={copyEmailDialogOpen}
        onRequestClose={() => setCopyEmailDialogOpen(false)}
        publicCourse={props.publicCourse}
      />
    </SideMenu>
  );
}

export function PublicCourseDetails(props: { publicCourse: PublicCourse }) {
  const [mobileView] = useContext(MobileViewContext);
  if (props.publicCourse.onlineCourse) {
    return null;
  }
  return (
    <div>
      <CourseProperty
        field={PublicCourseField.courseContactName}
        value={props.publicCourse.courseContactName}
      />
      <CourseProperty
        field={PublicCourseField.courseContactPhone}
        value={props.publicCourse.courseContactPhone}
        onClick={() =>
          window.open("tel:" + props.publicCourse.courseContactPhone)
        }
      />
      <CourseProperty
        field={PublicCourseField.courseCity}
        value={props.publicCourse.courseCity}
      />
      <CourseProperty
        field={PublicCourseField.courseLocation}
        value={props.publicCourse.courseLocation}
      />
      {mobileView ? (
        <a
          href={`https://waze.com/ul?q=${props.publicCourse.courseLocation}${
            " " + props.publicCourse.courseCity
          }`}
        >
          קישור לוויז
        </a>
      ) : (
        <>
          <CourseProperty
            field={PublicCourseField.distanceCost}
            value={props.publicCourse.distanceCost}
          />
          <CourseProperty
            field={PublicCourseField.mealCost}
            value={props.publicCourse.mealCost}
          />
          <CourseProperty
            field={PublicCourseField.projector}
            value={props.publicCourse.projector ? "כן" : "לא"}
          />
          <CourseProperty
            field={PublicCourseField.soundSystem}
            value={props.publicCourse.soundSystem ? "כן" : "לא"}
          />
          <CourseProperty
            field={PublicCourseField.microphone}
            value={props.publicCourse.microphone ? "כן" : "לא"}
          />
          <CourseProperty
            field={PublicCourseField.parking}
            value={props.publicCourse.parking ? "כן" : "לא"}
          />
          <CourseProperty
            field={PublicCourseField.roomsApproved}
            value={props.publicCourse.roomsApproved ? "כן" : "לא"}
          />
          <CourseProperty
            field={PublicCourseField.printedMaterials}
            value={props.publicCourse.printedMaterials ? "כן" : "לא"}
          />
          <CourseProperty
            field={PublicCourseField.printedCertificates}
            value={props.publicCourse.printedMaterials ? "כן" : "לא"}
          />
        </>
      )}
    </div>
  );
}

function CourseProperty(props: {
  field: PublicCourseField;
  value?: string;
  onClick?: () => void;
}) {
  if (!props.value) {
    return null;
  }
  const key =
    props.field === PublicCourseField.onlineCourse
      ? "סוג"
      : getFieldLabel(props.field);
  return (
    <div style={{ margin: "10px 0" }} onClick={props.onClick}>
      {key}: {props.value}
    </div>
  );
}

function MenuDivider() {
  return (
    <div style={{ margin: "20px 0" }}>
      <Divider />
    </div>
  );
}
