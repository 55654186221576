import { useState } from "react";
import Screen from "../../Components/Screen";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import CustomPaper from "../../Components/CustomComponents/CustomPaper";
import styles from "./Admin.module.scss";
import classnames from "classnames";
import ArticleIcon from "@mui/icons-material/Article";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { MoveOrderToOrganization } from "./MoveOrderToOrganizationForm";
import { DeleteOrganizationForm } from "./DeleteOrganizationForm";

const ICON_SIZE = 80;

export default function AdminPage() {
  const [selectedTool, setSelectedtool] = useState(0);

  return (
    <Screen>
      <CustomSection title="כלי ניהול מתקדמים">
        <AdminCard
          title="מחיקת ארגון"
          icon={
            <CorporateFareIcon
              style={{ fontSize: ICON_SIZE }}
              className={classnames(styles.icon, styles.organizationIcon)}
            />
          }
          onClick={() => {
            setSelectedtool(1);
          }}
        />
        <AdminCard
          title="העברת הזמנה לארגון אחר"
          icon={
            <ArticleIcon
              style={{ fontSize: ICON_SIZE }}
              className={classnames(styles.icon, styles.orderIcon)}
            />
          }
          onClick={() => {
            setSelectedtool(2);
          }}
        />
      </CustomSection>

      {/* TODO - Update index after these operations: */}
      {selectedTool === 1 && <DeleteOrganizationForm />}
      {selectedTool === 2 && <MoveOrderToOrganization />}
    </Screen>
  );
}

function AdminCard(props: {
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
}) {
  return (
    <div className={styles.cardContainer}>
      <CustomPaper className={styles.card} onClick={props.onClick}>
        <div>{props.icon}</div>
        {props.title}
      </CustomPaper>
    </div>
  );
}
