import CustomPaperTable from "../Components/Table/CustomPaperTable";
import Screen from "../Components/Screen";
import { getOrderPath, getPublicCourseSummaryPath } from "./Path";
import { useNavigate } from "react-router";
import SideMenu from "../Components/SideMenu";
import { useEffect, useState } from "react";
import { useVioletApi } from "../Auth/useFetchWithMsal";
import { FutureLecture } from "../generated/Api";

export default function FutureLecturesPage() {
  const navigate = useNavigate();
  const [futureLectures, setFutureLectures] = useState<FutureLecture[]>([]);
  const { callApi, isLoading } = useVioletApi();

  useEffect(() => {
    callApi((api) =>
      api.orders.getFutureLectures().then((res) => {
        if (res.data) {
          setFutureLectures(res.data);
        }
      }),
    );
  }, [callApi]);

  const internalCoursesLectureCount = futureLectures.filter(
    (x) => x.type === "OrderLecture",
  ).length;
  const publicCourseLecturesCount = futureLectures.filter(
    (x) => x.type === "PublicCourseLecture",
  ).length;

  const sideMenu = (
    <SideMenu title="סיכום הרצאות עתידיות">
      <div>הרצאות מתוכננות: {futureLectures.length}</div>
      <br />
      <div>הרצאות בארגונים: {internalCoursesLectureCount}</div>
      <br />
      <div>הרצאות בקורסים ציבוריים: {publicCourseLecturesCount}</div>
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu} isLoading={isLoading}>
      <CustomPaperTable
        title="הרצאות עתידיות"
        elements={futureLectures}
        tableHeaders={[
          { id: "מספר" },
          { lectureDate: "תאריך הרצאה" },
          { topic: "נושא" },
          { organizationName: "שם הארגון" },
        ]}
        onEditButton={(summary) => {
          switch (summary.type) {
            case "OrderLecture":
              navigate(getOrderPath(summary.id));
              return;

            case "PublicCourseLecture":
              navigate(getPublicCourseSummaryPath(summary.id));
              return;

            default:
              return;
          }
        }}
      />
    </Screen>
  );
}
