import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import * as _ from "lodash";
import { isEmptyValue } from "../../Util/StringUtil";
import { CustomIconButton } from "../CustomComponents/CustomButtons";
import styles from "./CustomTableRow.module.css";
import { IStringObject } from "../../Interfaces/IStringObject";
import { toPrintableDateFormat } from "../../Util/TimeUtil";
import { Field } from "../../Store/Appearance/RequiredFields/FieldNames";
import classNames from "classnames";
import { getMissingFields } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import React from "react";

interface CustomTableRowProps<TElement extends object> {
  element: TElement;
  headers: IStringObject[];
  onEditButton: (element: TElement) => void;
  onPickButton?: (element: TElement) => void;
  onDeleteButton?: (rowIndex: number) => void;
  error?: boolean;
  index: number;
  requiredFields?: Field[];
  renderCell?: (headerKey: string, element: TElement) => React.ReactNode | null;
}

export default function CustomTableRow<TElement extends object>(
  props: CustomTableRowProps<TElement>,
) {
  let error = false;
  if (props.requiredFields) {
    const missingFields = getMissingFields(props.element, props.requiredFields);
    error = missingFields.length > 0;
  }

  const onClickCell = (headerKey: string) => () => {
    if (!props.onEditButton) return;

    props.onEditButton(props.element);
  };

  const getSpecialCellContent = (headerKey: string, rowIndex: number) => {
    if (props.renderCell) {
      const cell = props.renderCell(headerKey, props.element);
      if (cell != null) {
        return cell;
      }
    }

    if (headerKey.toLowerCase().includes("date")) {
      if (isEmptyValue(props.element, headerKey)) {
        return null;
      }
      //@ts-ignore
      const value = props.element[headerKey];
      return (
        <div className={styles.cell} onClick={onClickCell(headerKey)}>
          {toPrintableDateFormat(value)}
        </div>
      );
    }

    switch (headerKey) {
      case "delete":
        return (
          <CustomIconButton
            style={{ height: 40, width: 40 }}
            onClick={() =>
              props.onDeleteButton && props.onDeleteButton(rowIndex)
            }
          >
            <DeleteIcon />
          </CustomIconButton>
        );

      default:
        return null;
    }
  };

  const headerKeys = props.headers.map((header) => Object.keys(header)[0]);

  return (
    <TableRow
      className={classNames({
        [styles.evenBackground]:
          props.index !== undefined && props.index % 2 === 0,
        [styles.error]: error,
      })}
    >
      {_.map(headerKeys, (headerKey: string) => (
        <TableCell key={headerKey}>
          {getSpecialCellContent(headerKey, props.index) || (
            <div className={styles.cell} onClick={onClickCell(headerKey)}>
              {
                //@ts-ignore
                props.element[headerKey] || ""
              }
            </div>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}
