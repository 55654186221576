import React, { useEffect, useState } from "react";
import AppDialog from "./Containers/Messages/AppDialogContainer";
import AppSnackbar from "./Containers/Messages/AppSnackBarContainer";
import WithGlobalTheme from "./ThemeDefault";
import { useDispatch } from "react-redux";
import styles from "./App.module.scss";
import "./variables.scss";
import AppMenu from "./Navigation/AppMenu";
import { useLocation } from "react-router";
import { Path } from "./Pages/Path";
import MobileViewContext, {
  isMobileScreen,
  isMobileView,
} from "./Navigation/MobileViewContext";
import { setInitialData } from "./Store/InitialData/Actions";
import { useVioletApi } from "./Auth/useFetchWithMsal";
import classNames from "classnames";
import SmallScreenMenu from "./Navigation/SmallScreenMenu";
import { loadFromCache, setCacheData } from "./LocalCache";

const SCROLL_TO_ID = "scroll-to";
const RESIZE_ID = "resize";

interface AppProps {
  children: React.ReactNode;
}

export default function App(props: AppProps) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [mobileView, setMobileView] = useState(isMobileView());
  const { callApi } = useVioletApi();

  useEffect(() => {
    loadFromCache((data) => dispatch(setInitialData(data)));
  }, [dispatch]);

  useEffect(() => {
    callApi((api) =>
      api.initialData.getInitialData().then((res) => {
        if (res.data) {
          dispatch(setInitialData(res.data));
          setCacheData(res.data);
        }
      }),
    );
  }, [dispatch, callApi]);

  useEffect(() => {
    // Scroll to top when page changes
    document.getElementById(SCROLL_TO_ID)!.scrollIntoView();
    document.title = getAppTitle(pathname);
  }, [pathname]);

  function handleWindowSizeChange() {
    setMobileView(isMobileView());
  }
  useEffect(() => {
    window.addEventListener(RESIZE_ID, handleWindowSizeChange);
    return () => {
      window.removeEventListener(RESIZE_ID, handleWindowSizeChange);
    };
  }, []);

  return (
    <WithGlobalTheme>
      <MobileViewContext.Provider value={[mobileView, setMobileView]}>
        <div
          className={classNames({
            [styles.app]: true,
            [styles.appMobileScreen]: isMobileScreen(),
          })}
        >
          {isMobileScreen() ? <SmallScreenMenu /> : <AppMenu />}

          <div className={styles.appContent}>
            <div id={SCROLL_TO_ID} />
            {props.children}
          </div>

          <AppDialog />
          <AppSnackbar />
        </div>
      </MobileViewContext.Provider>
    </WithGlobalTheme>
  );
}

function getAppTitle(pathname: string) {
  switch (pathname) {
    case Path.NewOrder:
      return "הזמנה חדשה";
    case Path.NewOrganization:
      return "ארגון חדש";
    case Path.EditPublicCourse:
      return "קורס ציבורי";
    case Path.PublicCourseSummary:
      return "עריכת קורס ציבורי";
    case Path.OpenPublicCourseOrders:
      return "הזמנות ציבוריות פתוחות";
    case Path.Organization:
      return "ארגון";
    case Path.FutureLectures:
      return "הרצאות עתידיות";
    case Path.ExpectedIncome:
      return "צבר הזמנות";
    case Path.WaitingPayment:
      return "ממתינות לתשלום";
    case Path.AllOrders:
      return "כל ההזמנות";
    case Path.AllPublicCourses:
      return "כל הקורסים הציבוריים";
    case Path.ActionRequired:
      return "התראות";
    case Path.Yaron:
      return "הרצאות של ירון";
    case Path.Bi:
      return "איך הגיעו אלינו";
    case Path.OfferTemplates:
      return "תבניות הצעות מחיר";
    case Path.UploadFiles:
      return "העלאת קבצים";
    case Path.Search:
      return "חיפוש";
    case Path.Admin:
      return "ניהול מתקדם";
    case Path.Phone:
    case Path.Home:
      return "Violet";

    default:
      if (pathname.startsWith(Path.NewOrderForOrg)) {
        return "הזמנה חדשה";
      }
      if (pathname.startsWith(Path.Order) || pathname.startsWith(Path.Phone)) {
        const parts = pathname.split("/");
        const id = parts.length < 3 ? "" : parts[2];
        return "הזמנה " + id;
      }
      if (pathname.startsWith(Path.EditPublicCourse)) {
        return "עריכת קורס ציבורי";
      }
      if (pathname.startsWith(Path.PublicCourseSummary)) {
        return "קורס ציבורי";
      }
      if (pathname.startsWith(Path.PublicCourseAttendance)) {
        return "דף נוכחות";
      }
      if (pathname.startsWith(Path.PublicCourseTestScores)) {
        return "ציונים";
      }
      if (pathname.startsWith(Path.Organization)) {
        return "ארגון";
      }
      if (pathname.startsWith(Path.ActionRequired)) {
        return "התראות";
      }
      if (pathname.startsWith(Path.Search)) {
        return "חיפוש";
      }
      console.warn("No page title for path: " + pathname);
      return "Violet";
  }
}
