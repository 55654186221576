import { useDispatch } from "react-redux";
import { openDialog, openSnackbar } from "../../Store/Appearance/Actions";
import SaveIcon from "@mui/icons-material/Save";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import { getFieldLabel } from "../../Store/Appearance/RequiredFields/FieldNames";
import { EXISTING_ORGANIZATION_REQUIRED_FIELDS } from "./OrganizationPage";
import { getMissingFields } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import { Organization } from "../../generated/Api";

export default function OrganizationSaveButton(props: {
  organization: Organization;
}) {
  const dispatch = useDispatch();
  const { callApi } = useVioletApi();

  const onSave = async () => {
    const missingFields = getMissingFields(
      props.organization,
      EXISTING_ORGANIZATION_REQUIRED_FIELDS,
    );
    if (missingFields.length > 0) {
      dispatch(
        openDialog(
          "שדות חובה חסרים:",
          missingFields.map(getFieldLabel).join(", "),
        ),
      );
      return;
    }

    // Update existing organization.
    callApi((api) =>
      api.organizations.updateOrganization(props.organization).then(() => {
        const snackbarMessage = `ארגון "${props.organization.name}" נשמר בהצלחה`;
        dispatch(openSnackbar(snackbarMessage));
      }),
    );
  };

  return (
    <CustomRaisedButton
      onClick={onSave}
      label="שמור"
      icon={<SaveIcon />}
      variant="contained"
      disabled={!props.organization.name}
      fullWidth
    />
  );
}
