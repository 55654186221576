export const TIES = [
  "איינשטיין",
  "ברגים",
  "גבינה עם עכבר",
  "גלידה",
  "דוד",
  "דולרים",
  "חשמלית",
  "חתול ועכבר",
  "כבשה שחורה רקע אדום",
  "כבשה שחורה רקע כחול",
  "כבשה שחורה רקע צהוב",
  "כובעים",
  "לוח אם",
  "מקלדת",
  "נורות",
  "סמיילי",
  "סמיילי עם משקפי שמש",
  "עניבה ענקית",
  "עץ",
  "פרות",
  "שלד",
  "שעם",
  "תשבץ",
];
