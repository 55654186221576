import { Size } from "../../../../Util/Constants/Size";
import GuestLecturerFields from "./GuestLecturerFields";
import CustomDivider from "../../../../Components/CustomComponents/CustomDivider";
import IndexAvatar from "../../../../Components/IndexAvatar";
import { toSuggestions } from "../../../../Components/AutoComplete";
import CustomAutoComplete from "../../../../Components/CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { getOfferedLectures } from "../../../../Store/InitialData/Selectors";
import styles from "./CourseLecturesInstance.module.css";
import classNames from "classnames";
import { PublicCourseLectureField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import CustomDatePicker from "../../../../Components/CustomComponents/CustomDatePicker";
import CustomTextField from "../../../../Components/CustomComponents/CustomTextField";
import { TIES } from "../../../../Util/Constants/Ties";
import { CustomIconButton } from "../../../../Components/CustomComponents/CustomButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomTimePicker from "../../../../Components/CustomComponents/CustomTimePicker";
import { PublicCourseLecture } from "../../../../generated/Api";

interface CourseLecturesInstanceProps {
  lecture: PublicCourseLecture;
  index: number;
  isOnlineCourse: boolean;
  deletable: boolean;
  onDelete: () => void;
  onFieldChange: (
    key: PublicCourseLectureField,
  ) => (newValue: string | boolean) => void;
  requiredFields: PublicCourseLectureField[];
}

export default function CourseLecturesInstance(
  props: CourseLecturesInstanceProps,
) {
  const publicCourseLecture = props.lecture;
  const offeredLectures = toSuggestions(useSelector(getOfferedLectures));
  const ties = toSuggestions(TIES);

  return (
    <div
      data-public-course-lecture-id={publicCourseLecture.id}
      className={classNames({
        [styles.instance]: true,
        [styles.evenBackground]: props.index % 2 === 0,
        [styles.borderTop]: props.index > 0, // The first index here is 1
      })}
    >
      <div className={styles.indexContainer}>
        <IndexAvatar index={props.index + 1} />
        <CustomIconButton
          onClick={props.onDelete}
          tooltip={"מחק הרצאה"}
          disabled={!props.deletable}
        >
          <DeleteIcon />
        </CustomIconButton>
      </div>
      <div className={styles.fields}>
        <CustomDatePicker
          field={PublicCourseLectureField.date}
          valueObject={publicCourseLecture}
          onChange={props.onFieldChange(PublicCourseLectureField.date)}
          requiredFields={props.requiredFields}
        />
        <CustomAutoComplete
          field={PublicCourseLectureField.topic}
          valueObject={publicCourseLecture}
          onTextChange={props.onFieldChange(PublicCourseLectureField.topic)}
          suggestions={offeredLectures}
          size={Size.X2L}
          requiredFields={props.requiredFields}
        />
        <CustomTimePicker
          field={PublicCourseLectureField.startTime}
          valueObject={publicCourseLecture}
          onChange={props.onFieldChange(PublicCourseLectureField.startTime)}
          requiredFields={props.requiredFields}
        />
        <CustomTimePicker
          field={PublicCourseLectureField.endTime}
          valueObject={publicCourseLecture}
          onChange={props.onFieldChange(PublicCourseLectureField.endTime)}
          requiredFields={props.requiredFields}
        />
        <CustomTextField
          field={PublicCourseLectureField.duration}
          valueObject={publicCourseLecture}
          onChange={props.onFieldChange(PublicCourseLectureField.duration)}
          size={Size.S}
          requiredFields={props.requiredFields}
        />
        <CustomAutoComplete
          field={PublicCourseLectureField.tie}
          valueObject={publicCourseLecture}
          onTextChange={props.onFieldChange(PublicCourseLectureField.tie)}
          suggestions={ties}
          size={Size.M}
          requiredFields={props.requiredFields}
        />
        <CustomTextField
          field={PublicCourseLectureField.price}
          valueObject={publicCourseLecture}
          onChange={props.onFieldChange(PublicCourseLectureField.price)}
          size={Size.M}
          requiredFields={props.requiredFields}
        />
        {props.isOnlineCourse ? (
          <CustomTextField
            field={PublicCourseLectureField.videoCallLink}
            valueObject={publicCourseLecture}
            onChange={props.onFieldChange(
              PublicCourseLectureField.videoCallLink,
            )}
            size={Size.X3L}
            requiredFields={props.requiredFields}
          />
        ) : (
          <>
            <CustomTextField
              field={PublicCourseLectureField.roomCost}
              valueObject={publicCourseLecture}
              onChange={props.onFieldChange(PublicCourseLectureField.roomCost)}
              size={Size.M}
              requiredFields={props.requiredFields}
            />
            <CustomTextField
              field={PublicCourseLectureField.pages}
              valueObject={publicCourseLecture}
              onChange={props.onFieldChange(PublicCourseLectureField.pages)}
              size={Size.M}
              requiredFields={props.requiredFields}
            />
          </>
        )}
        <GuestLecturerFields
          publicCourseLecture={publicCourseLecture}
          onFieldChange={props.onFieldChange}
          lectureId={publicCourseLecture.id}
          requiredFields={props.requiredFields}
        />
      </div>
      <CustomDivider />
    </div>
  );
}
