import CustomPaperTable from "../Components/Table/CustomPaperTable";
import { getOrderPath } from "./Path";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useVioletApi } from "../Auth/useFetchWithMsal";
import { FutureLecture } from "../generated/Api";

export default function PhonePage() {
  const navigate = useNavigate();
  const [futureLectures, setFutureLectures] = useState<FutureLecture[]>([]);
  const { callApi } = useVioletApi();

  useEffect(() => {
    callApi((api) =>
      api.orders.getFutureLectures({ orderLecturesOnly: true }).then((res) => {
        if (res.data) {
          setFutureLectures(res.data);
        }
      }),
    );
  }, [callApi]);

  return (
    <CustomPaperTable
      title="הרצאות עתידיות"
      elements={futureLectures}
      tableHeaders={[
        { id: "מספר הזמנה" },
        { lectureDate: "תאריך הרצאה" },
        { topic: "נושא" },
        { organizationName: "שם הארגון" },
      ]}
      onEditButton={(summary) => {
        navigate(getOrderPath(summary.id));
      }}
    />
  );
}
