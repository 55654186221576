import CustomCheckbox from "../../../../Components/CustomComponents/CustomCheckbox";
import {
  Field,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { Order } from "../../../../generated/Api";
import { OnOrderChangeFunction } from "../../OrderPage";

interface OrderCheckboxProps {
  field: OrderField;
  order: Order;
  requiredFields: Field[];
  onOrderChange: OnOrderChangeFunction;
  forceErrorColor?: boolean;
}
export default function OrderCheckbox(props: OrderCheckboxProps) {
  return (
    <CustomCheckbox
      field={props.field}
      valueObject={props.order}
      onChange={props.onOrderChange(props.field)}
      requiredFields={props.requiredFields}
      forceErrorColor={props.forceErrorColor}
    />
  );
}
