import OrderStatusStepper from "./OrderStatusStepper";
import styles from "./NewOrderPage.module.css";
import { useNavigate } from "react-router";
import { useState } from "react";
import Screen from "../../Components/Screen";
import { useSelector } from "react-redux";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import AutoComplete, { ISuggestion } from "../../Components/AutoComplete";
import { getOrganizationsIndex } from "../../Store/InitialData/Selectors";
import { Path } from "../Path";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";

export default function NewOrderPage() {
  const navigate = useNavigate();

  const [searchedOrganization, setSearchedOrganization] = useState("");
  const organizationIndex = useSelector(getOrganizationsIndex);
  const suggestions = organizationIndex.map<ISuggestion>((index) => ({
    key: index.id + "",
    label: index.organizationName,
  }));

  return (
    <Screen>
      <div className={styles.content}>
        <OrderStatusStepper />

        <CustomSection title="בחר ארגון">
          <div className={styles.organizationSelectorContainer}>
            <div className={styles.selectorOption}>
              <AutoComplete
                hintText="חיפוש ארגון קיים"
                suggestions={suggestions}
                textFieldValue={searchedOrganization}
                onTextChange={setSearchedOrganization}
                onSuggestionSelected={(selected) =>
                  navigate(Path.NewOrderForOrg + "/" + selected?.key)
                }
                fullWidth
                autoFocus
              />
            </div>
            <div className={styles.divider} />
            <div className={styles.selectorOption}>
              <CustomRaisedButton
                icon={<DomainAddIcon />}
                variant="outlined"
                label="צור ארגון חדש"
                onClick={() => navigate(Path.NewOrganization)}
              />
            </div>
          </div>
        </CustomSection>
      </div>
    </Screen>
  );
}
