import { useState, useCallback } from "react";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { LOGIN_SCOPE } from "./AuthConfig";
import { VioletApi } from "../generated/Api";

const isProd = process.env.NODE_ENV === "production";
const DEV_BASE_URL = "http://localhost:7071/api";
const PROD_BASE_URL = "https://func-violet-prod.azurewebsites.net/api";
export const BASE_URL = isProd ? PROD_BASE_URL : DEV_BASE_URL;

export const useVioletApi = () => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const { result: authResult, error: authError } = useMsalAuthentication(
    InteractionType.Popup,
    {
      scopes: [LOGIN_SCOPE],
      account: instance.getActiveAccount()!,
      redirectUri: "/redirect",
    },
  );

  const callApi = (call: (api: VioletApi<unknown>) => Promise<void>) => {
    if (!authResult || authError) {
      return null;
    }

    const bearer = `Bearer ${authResult?.accessToken}`;
    const api = new VioletApi({
      baseUrl: BASE_URL,
      baseApiParams: {
        headers: {
          Authorization: bearer,
        },
      },
    });
    setIsLoading(true);
    return call(api)
      .then(() => setIsLoading(false))
      .catch((e) => {
        alert(
          "התרחשה שגיאה בשרת. יש לנסות לטעון את הדף מחדש. אם עדיין לא עובד יש לדווח לירון מה הפעולה שניסית לעשות ונכשלה\n" +
            e,
        );
        setIsLoading(false);
        throw e;
      });
  };

  return {
    isLoading,
    callApi: useCallback(callApi, [authResult, authError]), // to avoid infinite calls when inside a `useEffect;
  };
};
