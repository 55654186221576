import CustomDialog from "../../../../Components/CustomComponents/CustomDialog";
import React, { useState } from "react";
import CustomTextField from "../../../../Components/CustomComponents/CustomTextField";
import {
  ContacField,
  Field,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { Size } from "../../../../Util/Constants/Size";
import { updateObject } from "../../../../Util/ObjectUpdater";
import { CustomRaisedButton } from "../../../../Components/CustomComponents/CustomButtons";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SaveIcon from "@mui/icons-material/Save";
import { useVioletApi } from "../../../../Auth/useFetchWithMsal";
import { Organization, OrganizationContact } from "../../../../generated/Api";

interface SetContactDialogProps {
  dialogOpen: boolean;
  onRequestClose: () => void;
  contact?: OrganizationContact;
  organization: Organization;
  setOrganization: (newOrg: Organization) => void;
  onContactCreated: (contactId: number | undefined) => void;
}

export default function SetContactDialog(props: SetContactDialogProps) {
  const { callApi } = useVioletApi();

  const [updatedContact, setUpdatedContact] = useState<OrganizationContact>(
    props.contact || ({} as OrganizationContact),
  );

  const onUpdateField = (key: ContacField) => (value: string) => {
    setUpdatedContact((c) =>
      updateObject(c, {
        [key]: value,
      }),
    );
  };

  const onSave = async () => {
    callApi((api) =>
      api.organizations
        .setContact({
          organizationId: props.organization.id,
          organizationContact: updatedContact,
        })
        .then((res) => {
          if (!res?.data) return;
          props.setOrganization(res.data.organization);
          props.onRequestClose();
          props.onContactCreated(res.data.contactId);
        }),
    );
  };

  const requriedFields: Field[] = [];

  const actions: React.ReactNode[] = [];
  if (props.contact) {
    actions.push(
      <CustomRaisedButton
        key={"deleteContact." + props.contact.id}
        label="איש קשר חדש"
        onClick={() => {
          props.onRequestClose();
          props.onContactCreated(undefined);
        }}
        variant="outlined"
        icon={<PersonAddIcon />}
      />,
    );
    actions.push(
      <CustomRaisedButton
        key={"updateContact." + props.contact.id}
        label="עדכן איש קשר"
        onClick={onSave}
        variant="contained"
        style={{ margin: 20 }}
        icon={<SaveIcon />}
      />,
    );
  } else {
    actions.push(
      <CustomRaisedButton
        key={"createContact"}
        label="צור איש קשר"
        onClick={onSave}
        variant="contained"
        style={{ margin: 20 }}
        icon={<PersonAddIcon />}
      />,
    );
  }

  return (
    <CustomDialog
      title={
        props.contact
          ? "עדכן איש קשר"
          : "איש קשר חדש - " + props.organization.name
      }
      open={props.dialogOpen}
      onRequestClose={props.onRequestClose}
      actions={actions}
    >
      <CustomTextField
        field={ContacField.firstName}
        onChange={onUpdateField(ContacField.firstName)}
        valueObject={updatedContact}
        requiredFields={requriedFields}
      />
      <CustomTextField
        field={ContacField.lastName}
        onChange={onUpdateField(ContacField.lastName)}
        valueObject={updatedContact}
        requiredFields={requriedFields}
      />
      <CustomTextField
        field={ContacField.job}
        onChange={onUpdateField(ContacField.job)}
        valueObject={updatedContact}
        requiredFields={requriedFields}
      />
      <CustomTextField
        field={ContacField.phone1}
        onChange={onUpdateField(ContacField.phone1)}
        valueObject={updatedContact}
        requiredFields={requriedFields}
      />
      <CustomTextField
        field={ContacField.email}
        onChange={onUpdateField(ContacField.email)}
        valueObject={updatedContact}
        requiredFields={requriedFields}
        size={Size.X2L}
      />
      <CustomTextField
        field={ContacField.phone2}
        onChange={onUpdateField(ContacField.phone2)}
        valueObject={updatedContact}
        requiredFields={requriedFields}
      />
      <CustomTextField
        field={ContacField.phoneExtension}
        onChange={onUpdateField(ContacField.phoneExtension)}
        valueObject={updatedContact}
        requiredFields={requriedFields}
      />
    </CustomDialog>
  );
}
