import CustomPaperTable from "../Components/Table/CustomPaperTable";
import Screen from "../Components/Screen";
import { getOrderPath } from "./Path";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useVioletApi } from "../Auth/useFetchWithMsal";
import { OrderSummary } from "../generated/Api";

export default function YaronLecturesPage() {
  const navigate = useNavigate();
  const { callApi, isLoading } = useVioletApi();

  const [ordersSummary, setOrderssSummary] = useState<OrderSummary[]>([]);

  useEffect(() => {
    callApi((api) =>
      api.orders.getYaronOrders().then((res) => {
        setOrderssSummary(res?.data || []);
      }),
    );
  }, [callApi]);

  return (
    <Screen isLoading={isLoading}>
      <CustomPaperTable
        title="הרצאות של ירון"
        elements={ordersSummary}
        tableHeaders={[
          { id: "מספר הזמנה" },
          { lectureDate: "תאריך הרצאה" },
          { organizationName: "שם הארגון" },
          { topic: "נושא" },
          { status: "סטאטוס" },
        ]}
        onEditButton={(summary) => navigate(getOrderPath(summary.id))}
      />
    </Screen>
  );
}
