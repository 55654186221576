import _ from "lodash";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { OnOrderChangeFunction } from "../../OrderPage";
import { Order } from "../../../../generated/Api";

// When updating this, update the same variable in the offer app as well.
export const TRAVEL_COST_PRICE = 5.87;

const vatRate = 0.17;
export default function calculateSum(
  order: Order,
  onOrderChange: OnOrderChangeFunction,
) {
  try {
    const cost = calculateMathValue(order.cost);
    if (cost === 0 && order.cost !== "0") return;

    const travelExpenses = _.round(
      TRAVEL_COST_PRICE * 2 * calculateMathValue(order.oneWayDistance),
      2,
    );
    const sum = _.round(
      cost + travelExpenses + calculateMathValue(order.extraCosts),
      2,
    );
    const vat = _.round(vatRate * sum, 2);
    const totalSum = _.round(sum + vat);

    onOrderChange(OrderField.travelExpenses)(getValue(travelExpenses));
    onOrderChange(OrderField.sum)(getValue(sum));
    onOrderChange(OrderField.vat)(getValue(vat));
    onOrderChange(OrderField.totalSum)("" + totalSum);
  } catch (e) {
    return;
  }
}

function getValue(num: number) {
  return num === 0 ? "" : num.toFixed(2);
}

export function calculateMathValue(value?: string) {
  if (!value || value === "") return 0;

  const regexGroups = /^(\d+\.?\d*)\*?(\d*\.?\d*)?$/g.exec(value);
  if (regexGroups === null) throw new Error("Invalid format");

  if (regexGroups[2] === undefined) return parseFloat(regexGroups[1]);

  return parseFloat(regexGroups[1]) * parseFloat(regexGroups[2]);
}
