import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import { OrganizationOrderSummary } from "../../generated/Api";
import { getOrderPath } from "../Path";
import { useNavigate } from "react-router";

export default function OrganizationsOrderTable(props: {
  organizationOrdersSummary: OrganizationOrderSummary[];
}) {
  const navigate = useNavigate();

  return (
    <CustomPaperTable
      title="הזמנות"
      elements={props.organizationOrdersSummary}
      tableHeaders={[
        { orderId: "מספר הזמנה" },
        { lectureDate: "תאריך הרצאה" },
        { topic: "נושא" },
        { lecturesCount: "מספר הרצאות" },
        { status: "סטאטוס" },
        { proformaInvoiceNumber: "חשבונית עסקה" },
      ]}
      onEditButton={(summary) => {
        navigate(getOrderPath(summary.orderId));
      }}
    />
  );
}
