import CustomPaperTable from "../Components/Table/CustomPaperTable";
import Screen from "../Components/Screen";
import SideMenu from "../Components/SideMenu";
import { getOrderPath, Path } from "./Path";
import { useNavigate, useParams } from "react-router";
import Checkbox from "../Components/Checkbox";
import { useEffect, useState } from "react";
import { useVioletApi } from "../Auth/useFetchWithMsal";
import { useDispatch } from "react-redux";
import { setAlertsCount } from "../Store/InitialData/Actions";
import { OrderWithAlert } from "../generated/Api";

export default function ActionRequiredPage() {
  const navigate = useNavigate();
  const { filters } = useParams<{ filters?: string }>();
  const dispatch = useDispatch();
  const { callApi, isLoading } = useVioletApi();

  const [orders, setOrders] = useState<OrderWithAlert[]>([]);

  useEffect(() => {
    callApi((api) =>
      api.orders.getOrdersWithAlert().then((res) => {
        if (res.data) {
          setOrders(res.data);
          dispatch(setAlertsCount(res.data.length));
        }
      }),
    );
  }, [dispatch, callApi]);

  const selectedCasesArray = !filters
    ? []
    : (filters
        .split(",")
        .map((numberString) => {
          const res = ALL_ACTION_REQUIRED_CASES.filter(
            (x) => x === numberString,
          );
          if (res.length > 0) {
            return res[0] as ActionRequiredCase;
          }
          return null;
        })
        .filter((x) => x !== null) as ActionRequiredCase[]);

  function CaseCheckbox(props: { case: ActionRequiredCase }) {
    return (
      <Checkbox
        label={getActionRequiredCaseLabel(props.case)}
        value={selectedCasesArray.includes(props.case)}
        onChange={(selected) => {
          let path: string;
          if (selected) {
            path = filters ? filters + "," + props.case : props.case;
          } else {
            path = selectedCasesArray.filter((c) => c !== props.case).join(",");
          }
          navigate(Path.ActionRequired + "/" + path);
        }}
      />
    );
  }

  const filteredOrdersWithAlerts = orders.filter(
    (orderWithAlert) =>
      selectedCasesArray.length === 0 ||
      selectedCasesArray.includes(
        toActionRequiredCase(orderWithAlert.alertCase),
      ),
  );

  const sideMenu = (
    <SideMenu title="סינון לפי התראה:">
      {ALL_ACTION_REQUIRED_CASES.map((actionCase) => (
        <CaseCheckbox case={actionCase} key={actionCase} />
      ))}
      <div style={{ marginTop: 20 }}>
        {"הזמנות לאחר סינון: " + filteredOrdersWithAlerts.length}
      </div>
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu} isLoading={isLoading}>
      <CustomPaperTable
        title="התראות"
        elements={filteredOrdersWithAlerts}
        tableHeaders={[
          { orderId: "מספר הזמנה" },
          { createdDate: "תאריך יצירת הזמנה" },
          { organizationName: "שם הארגון" },
          { status: "סטאטוס הזמנה" },
          { lectureDate: "תאריך הרצאה" },
          { alertMessage: "התראה" },
        ]}
        onEditButton={(orderSummary: OrderWithAlert) => {
          navigate(getOrderPath(orderSummary.orderId));
        }}
      />
    </Screen>
  );
}

enum ActionRequiredCase {
  EXECUTED_AND_NO_INVOICE = "1",
  FOLLOW_UP_REQUIRED = "2",
  NOT_PAID_ON_TIME = "3",
  APPROVED_BY_CUSTOMER = "4",
  NO_ORDER_APPROVAL = "5",
  MISSING_CERTIFICATE = "6",
  TWO_WEEKS_PASSED_FROM_CREATION = "7",
}

const ALL_ACTION_REQUIRED_CASES = [
  ActionRequiredCase.EXECUTED_AND_NO_INVOICE,
  ActionRequiredCase.FOLLOW_UP_REQUIRED,
  ActionRequiredCase.NOT_PAID_ON_TIME,
  ActionRequiredCase.APPROVED_BY_CUSTOMER,
  ActionRequiredCase.NO_ORDER_APPROVAL,
  ActionRequiredCase.MISSING_CERTIFICATE,
  ActionRequiredCase.TWO_WEEKS_PASSED_FROM_CREATION,
];

function getActionRequiredCaseLabel(actionCase: ActionRequiredCase) {
  switch (actionCase) {
    case ActionRequiredCase.EXECUTED_AND_NO_INVOICE:
      return "לא הונפקה חשבונית";
    case ActionRequiredCase.FOLLOW_UP_REQUIRED:
      return "נדרש המשך טיפול";
    case ActionRequiredCase.NOT_PAID_ON_TIME:
      return "תאריך לתשלום עבר";
    case ActionRequiredCase.APPROVED_BY_CUSTOMER:
      return "ההזמנה אושרה על ידי הלקוח";
    case ActionRequiredCase.NO_ORDER_APPROVAL:
      return "ההזמנה לא אושרה, ההרצאה בקרוב";
    case ActionRequiredCase.MISSING_CERTIFICATE:
      return "לא נשלחה תעודה";
    case ActionRequiredCase.TWO_WEEKS_PASSED_FROM_CREATION:
      return "חלפו שבועיים מיצירת ההזמנה";
    default:
      throw Error("Invalid action case to string: " + actionCase);
  }
}

export function isCriticalCase(actionCase: ActionRequiredCase) {
  switch (actionCase) {
    case ActionRequiredCase.EXECUTED_AND_NO_INVOICE:
    case ActionRequiredCase.FOLLOW_UP_REQUIRED:
    case ActionRequiredCase.NOT_PAID_ON_TIME:
    case ActionRequiredCase.APPROVED_BY_CUSTOMER:
    case ActionRequiredCase.MISSING_CERTIFICATE:
      return true;
    case ActionRequiredCase.NO_ORDER_APPROVAL:
    case ActionRequiredCase.TWO_WEEKS_PASSED_FROM_CREATION:
      return false;
    default:
      throw Error("Invalid action case " + actionCase);
  }
}

export function toActionRequiredCase(alertCase: string): ActionRequiredCase {
  return ActionRequiredCase[alertCase as keyof typeof ActionRequiredCase];
}
