import {
  Field,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import CustomDatePicker from "../../../../Components/CustomComponents/CustomDatePicker";
import { OnOrderChangeFunction } from "../../OrderPage";
import { Order } from "../../../../generated/Api";

interface OrderDatePickerProps {
  field: OrderField;
  order: Order;
  requiredFields: Field[];
  onOrderChange: OnOrderChangeFunction;
  fullWidth?: boolean;
}

export default function OrderDatePicker(props: OrderDatePickerProps) {
  return (
    <CustomDatePicker
      field={props.field}
      valueObject={props.order}
      requiredFields={props.requiredFields}
      onChange={props.onOrderChange(props.field)}
      fullWidth={props.fullWidth}
    />
  );
}
