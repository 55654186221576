import {
  Field,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { Size } from "../../../../Util/Constants/Size";
import CustomTextField from "../../../../Components/CustomComponents/CustomTextField";
import { OnOrderChangeFunction } from "../../OrderPage";
import { TextFieldType } from "../../../../Components/TextField";
import { Order } from "../../../../generated/Api";

interface OrderTextFieldProps {
  field: OrderField;
  order: Order;
  requiredFields: Field[];
  onOrderChange: OnOrderChangeFunction;
  fullWidth?: boolean;
  size?: Size;
  disabled?: boolean;
  type?: TextFieldType;
  singelLine?: boolean;
}

export default function OrderTextField(props: OrderTextFieldProps) {
  return (
    <CustomTextField
      field={props.field}
      valueObject={props.order}
      requiredFields={props.requiredFields}
      onChange={props.onOrderChange(props.field)}
      fullWidth={props.fullWidth}
      size={props.size}
      disabled={props.disabled}
      type={props.type}
      singelLine={props.singelLine}
    />
  );
}
