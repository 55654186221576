import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import Screen from "../../Components/Screen";
import { getOrderPath } from "../Path";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import {
  OrderField,
  OrganizationField,
  PublicCourseField,
  PublicCourseParticipantField,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { OpenPublicCourseOrder } from "../../generated/Api";

export default function OpenPublicCourseOrdersPage() {
  const [openParticipants, setOpenParticipants] = useState<
    OpenPublicCourseOrder[]
  >([]);
  const { callApi, isLoading } = useVioletApi();

  useEffect(() => {
    callApi((api) =>
      api.publicCourses.getOpenPublicCourseOrders().then((res) => {
        if (res.data) {
          setOpenParticipants(res.data);
        }
      }),
    );
  }, [callApi]);

  const navigate = useNavigate();

  return (
    <Screen isLoading={isLoading}>
      <CustomPaperTable
        title="הזמנות ציבוריות פתוחות"
        elements={openParticipants}
        limit={30}
        tableHeaders={[
          { orderId: getFieldLabel(OrderField.id) },
          { organizationName: getFieldLabel(OrganizationField.name) },
          { firstName: getFieldLabel(PublicCourseParticipantField.firstName) },
          { lastName: getFieldLabel(PublicCourseParticipantField.lastName) },
          { courseName: getFieldLabel(PublicCourseField.courseName) },
          {
            desiredLectures: getFieldLabel(
              PublicCourseParticipantField.desiredLectures,
            ),
          },
        ]}
        onEditButton={(participant) => {
          navigate(getOrderPath(participant.orderId));
        }}
      />
    </Screen>
  );
}
