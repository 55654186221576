import React from "react";
import { CustomFlatButton } from "./CustomButtons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function CustomDialog(props: CustomDialogProps) {
  const actions = [
    <CustomFlatButton key="ok" label="אישור" onClick={props.onRequestClose} />,
  ];

  return (
    <Dialog open={props.open} onClose={props.onRequestClose} maxWidth="lg">
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent style={{ whiteSpace: "pre-wrap" }}>
        {props.children}
      </DialogContent>
      <DialogActions>{props.actions ? props.actions : actions}</DialogActions>
    </Dialog>
  );
}

interface CustomDialogProps {
  open: boolean;
  title: string;
  onRequestClose: () => void;
  actions?: React.ReactNode[];
  children?: React.ReactNode;
}
