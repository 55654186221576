import { createOptions } from "../../Components/CustomComponents/IOption";
import {
  Field,
  OrderField,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { Order, OrderStatusEnum } from "../../generated/Api";
import { OnOrderChangeFunction } from "./OrderPage";
import { isMatchingStatus } from "./OrderStatus/OrderStatusUtils";
import OrderCheckbox from "./Sections/ConnectedCustomComponents/OrderCheckbox";
import OrderSelectField from "./Sections/ConnectedCustomComponents/OrderSelectField";
import OrderTextField from "./Sections/ConnectedCustomComponents/OrderTextField";

const CANELLATION_REASONS = [
  "אין תקשורת עם הלקוח",
  "המחיר יקר מדי",
  "חנן לא פנוי בתאריך זה",
  "יום העיון של הלקוח בוטל",
  "יום העיון של הלקוח נדחה",
  "מצב בטחוני",
  "נבחר ספק אחר",
  "נושא לא רלוונטי",
  "וירוס הקורונה",
];

export default function OrderApprovalBox(props: {
  order: Order;
  onOrderChange: OnOrderChangeFunction;
  requiredFields: Field[];
}) {
  const rejectionReasons = createOptions(CANELLATION_REASONS);
  const cancellationReasons = createOptions(CANELLATION_REASONS);
  const showCancelledCheckBox = isMatchingStatus(props.order, [
    OrderStatusEnum.ApprovedOrder,
    OrderStatusEnum.IsExecuting,
    OrderStatusEnum.Cancelled,
  ]);

  return (
    <div>
      <OrderCheckbox
        field={OrderField.approved}
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
      />
      <OrderCheckbox
        field={OrderField.rejected}
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        forceErrorColor
      />

      {props.order.rejected && (
        <>
          <OrderSelectField
            options={rejectionReasons}
            field={OrderField.rejectionReason}
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            fullWidth
          />
          <OrderTextField
            field={OrderField.rejectionDetails}
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            fullWidth
          />
        </>
      )}

      {showCancelledCheckBox && (
        <OrderCheckbox
          field={OrderField.cancelled}
          order={props.order}
          onOrderChange={props.onOrderChange}
          requiredFields={props.requiredFields}
          forceErrorColor
        />
      )}

      {props.order.cancelled && (
        <>
          <OrderSelectField
            options={cancellationReasons}
            field={OrderField.cancellationReason}
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            fullWidth
          />
          <OrderTextField
            field={OrderField.cancellationDetails}
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            fullWidth
          />
        </>
      )}
    </div>
  );
}
