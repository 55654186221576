import { IState } from "../../Interfaces/ReduxInterfaces";
import _ from "lodash";
import { OfferedLecture } from "../../generated/Api";

export function getFullOfferedLectures(state: IState) {
  return state.initialData.offeredLectures;
}

export function getOfferedLecture(
  state: IState,
  topic: string,
): OfferedLecture | undefined {
  return getFullOfferedLectures(state)[topic] || undefined;
}

export function getOfferedLectures(state: IState): string[] {
  const templates = getFullOfferedLectures(state);
  return _.keys(templates);
}

export function getOrganizationsIndex(state: IState) {
  return state.initialData.organizationsIndex;
}

export function getOrdersIndex(state: IState) {
  return state.initialData.ordersIndex;
}

export function getPublicCoursesIndex(state: IState) {
  return state.initialData.publicCoursesIndex;
}

export const isDuplicateOrganizationId =
  (state: IState) => (companyId?: string, organizationIdToExclude?: number) => {
    return (
      getOrganizationsIndex(state).filter(
        (x) =>
          companyId &&
          x.companyId === companyId &&
          x.id !== organizationIdToExclude,
      ).length > 0
    );
  };

export function getAlertsCount(state: IState) {
  return state.initialData.alertsCount;
}
