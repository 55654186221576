import OrderCheckbox from "../ConnectedCustomComponents/OrderCheckbox";
import {
  Field,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import OrderDatePicker from "../ConnectedCustomComponents/OrderDatePicker";
import { OnOrderChangeFunction } from "../../OrderPage";
import { Order } from "../../../../generated/Api";

export default function FollowUpSection(props: {
  order: Order;
  onOrderChange: OnOrderChangeFunction;
  requiredFields: Field[];
}) {
  const followUpRequired = props.order.followUpRequired;

  return (
    <>
      <OrderCheckbox
        field={OrderField.followUpRequired}
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
      />

      {followUpRequired && (
        <>
          <OrderDatePicker
            field={OrderField.followUpDate}
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            fullWidth
          />

          <OrderTextField
            field={OrderField.followUpDetails}
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            fullWidth
          />
        </>
      )}
    </>
  );
}
