import CustomSection from "../../../../../Components/CustomComponents/CustomSection";
import { Size } from "../../../../../Util/Constants/Size";
import styles from "./CourseLocationDetails.module.css";
import {
  Field,
  OrderField,
} from "../../../../../Store/Appearance/RequiredFields/FieldNames";
import OrderCheckbox from "../../ConnectedCustomComponents/OrderCheckbox";
import OrderTextField from "../../ConnectedCustomComponents/OrderTextField";
import { useContext } from "react";
import MobileViewContext from "../../../../../Navigation/MobileViewContext";
import CustomDivider from "../../../../../Components/CustomComponents/CustomDivider";
import { OnOrderChangeFunction } from "../../../OrderPage";
import { Order } from "../../../../../generated/Api";

export default function CourseLocationDetails(props: {
  order: Order;
  onOrderChange: OnOrderChangeFunction;
  requiredFields: Field[];
}) {
  const [mobile] = useContext(MobileViewContext);
  return (
    <CustomSection title="מיקום">
      <div className={styles.detailsSection}>
        <div className={mobile ? "" : styles.lectureDetailsFields}>
          <OrderTextField
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            field={OrderField.street}
          />
          <OrderTextField
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            field={OrderField.streetNumber}
            size={Size.S}
          />
          <OrderTextField
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            field={OrderField.city}
          />
          <OrderTextField
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            field={OrderField.location}
            size={Size.XL}
          />
          <OrderTextField
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            field={OrderField.audienceType}
          />
          <OrderTextField
            order={props.order}
            onOrderChange={props.onOrderChange}
            requiredFields={props.requiredFields}
            field={OrderField.daySchedule}
          />
        </div>
        {mobile ? (
          <>
            <a
              href={`https://waze.com/ul?q=${props.order.street}${
                " " + props.order.streetNumber
              } ${props.order.city}`}
            >
              קישור לוויז
            </a>
            <CustomDivider />
          </>
        ) : (
          <div className={styles.lectureDetailsFields}>
            <OrderCheckbox
              order={props.order}
              onOrderChange={props.onOrderChange}
              requiredFields={props.requiredFields}
              field={OrderField.sameAudience}
            />
            <OrderCheckbox
              order={props.order}
              onOrderChange={props.onOrderChange}
              requiredFields={props.requiredFields}
              field={OrderField.projector}
            />
            <OrderCheckbox
              order={props.order}
              onOrderChange={props.onOrderChange}
              requiredFields={props.requiredFields}
              field={OrderField.soundSystem}
            />
            <OrderCheckbox
              order={props.order}
              onOrderChange={props.onOrderChange}
              requiredFields={props.requiredFields}
              field={OrderField.microphone}
            />
            <OrderCheckbox
              order={props.order}
              onOrderChange={props.onOrderChange}
              requiredFields={props.requiredFields}
              field={OrderField.parking}
            />
          </div>
        )}
      </div>
    </CustomSection>
  );
}
