import {
  getFieldLabel,
  LectureTimeField,
} from "../../../../../../Store/Appearance/RequiredFields/FieldNames";
import { calculatePreparationTimes } from "../../../../../../Util/TimeUtil";
import { OrderLecture } from "../../../../../../generated/Api";

export default function PreparationTimes(props: { lectureTime: OrderLecture }) {
  const preparationTimes = calculatePreparationTimes(props.lectureTime);
  if (!preparationTimes) {
    return null;
  }

  return (
    <div style={styles.container}>
      <Field
        valueObject={preparationTimes}
        field={LectureTimeField.wakeUpTime}
      />
      <Field
        valueObject={preparationTimes}
        field={LectureTimeField.leaveHomeTime}
      />
      <Field
        valueObject={preparationTimes}
        field={LectureTimeField.arriveTime}
      />
      <Field
        valueObject={preparationTimes}
        field={LectureTimeField.lectureStartTime}
      />
    </div>
  );
}

const styles = {
  container: {
    display: "flex" as "flex",
    flexDirection: "row" as "row",
    paddingLeft: 45,
    paddingTop: 20,
  },
  field: {
    marginBottom: 10,
    marginLeft: 20,
  },
  bold: {
    fontWeight: "bold" as "bold",
  },
};

function Field(props: { valueObject: any; field: LectureTimeField }) {
  const value = props.valueObject[props.field];
  return (
    <div style={styles.field}>
      <span style={styles.bold}>{getFieldLabel(props.field)}: </span>
      <span>{value}</span>
    </div>
  );
}
