import * as actionTypes from "./ActionTypes";
import { LOGGED_OUT } from "../Firebase/ActionTypes";
import { createImmutable, mergeImmutable } from "../../Util/ObjectUpdater";
import { InitialData } from "../../generated/Api";

const initialState: InitialData = {
  alertsCount: 0,
  offeredLectures: {},
  organizationsIndex: [],
  ordersIndex: [],
  publicCoursesIndex: [],
};

export default (state = createImmutable(initialState), action: any = {}) => {
  switch (action.type) {
    case actionTypes.RECEIVE_INITIAL_DATA:
      return createImmutable(action.payload);

    case actionTypes.UPDATE_OFFERED_LECTURES:
      return mergeImmutable(state, {
        offeredLectures: action.payload,
      });

    case actionTypes.ADD_NEW_ORGANIZATION_INDEX:
      return mergeImmutable(state, {
        organizationsIndex: [...state.organizationsIndex, action.payload],
      });

    case actionTypes.ADD_NEW_ORDER_INDEX:
      return mergeImmutable(state, {
        ordersIndex: [...state.ordersIndex, action.payload],
      });

    case actionTypes.ADD_NEW_PUBLIC_COURSE_INDEX:
      return mergeImmutable(state, {
        publicCoursesIndex: [action.payload, ...state.publicCoursesIndex],
      });

    case actionTypes.SET_ALERTS_COUNT:
      return mergeImmutable(state, {
        alertsCount: action.payload,
      });

    case LOGGED_OUT:
      return createImmutable(initialState);

    default:
      return state;
  }
};
