import {
  getFieldLabel,
  OrderField,
} from "../Store/Appearance/RequiredFields/FieldNames";
import { toPrintableDateFormat } from "../Util/TimeUtil";
import styles from "./ChangeTimes.module.scss";

export default function ChangeTimes(props: {
  createdDate: string;
  changedDate: string;
}) {
  return (
    <div className={styles.times}>
      <span>{getFieldLabel(OrderField.createdDate)}</span>
      <span>-</span>
      <span>{toPrintableDateFormat(props.createdDate)}</span>
      <span>{getFieldLabel(OrderField.changedDate)}</span>
      <span>-</span>
      <span>{toPrintableDateFormat(props.changedDate)}</span>
    </div>
  );
}
