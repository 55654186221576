import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

export function CustomIconButton(props: CustomIconButtonProps) {
  if (!props.tooltip || props.disabled)
    return (
      <IconButton
        style={props.style}
        onClick={props.onClick}
        size="large"
        disabled={props.disabled}
      >
        {props.children}
      </IconButton>
    );

  return (
    <Tooltip title={props.tooltip}>
      <IconButton
        style={props.style}
        onClick={props.onClick}
        size="large"
        disabled={props.disabled}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  );
}

interface CustomIconButtonProps extends CustomFlatButtonProps {
  children?: React.ReactNode;
  tooltip?: string;
  disabled?: boolean;
}

export function CustomRaisedButton(props: CustomRaisedButtonProps) {
  return (
    <Button
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled}
      variant={props.variant}
      startIcon={props.icon}
      fullWidth={props.fullWidth}
    >
      {props.label}
    </Button>
  );
}

interface CustomRaisedButtonProps extends CustomFlatButtonProps {
  disabled?: boolean;
  label: string;
}

export function CustomFlatButton(props: CustomFlatButtonProps) {
  return (
    <Button onClick={props.onClick} startIcon={props.icon} style={props.style}>
      {props.label}
    </Button>
  );
}

interface CustomFlatButtonProps {
  onClick: () => void;
  label?: string;
  style?: React.CSSProperties;
  variant?: "text" | "outlined" | "contained";
  icon?: React.ReactNode;
  fullWidth?: boolean;
}
