import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import Screen from "../../Components/Screen";
import { getPublicCourseSummaryPath } from "../Path";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import { PublicCourseSummary } from "../../generated/Api";

export default function AllPublicCoursesPage() {
  const [coursesSummary, setCoursesSummary] = useState<PublicCourseSummary[]>(
    [],
  );
  const { callApi, isLoading } = useVioletApi();

  useEffect(() => {
    callApi((api) =>
      api.publicCourses.getAllPublicCourses().then((res) => {
        if (res.data) {
          setCoursesSummary(res.data);
        }
      }),
    );
  }, [callApi]);

  const navigate = useNavigate();

  return (
    <Screen isLoading={isLoading}>
      <CustomPaperTable
        title="כל הקורסים הציבוריים"
        elements={coursesSummary}
        limit={30}
        tableHeaders={[
          { courseName: "שם הקורס" },
          { courseLocation: "מיקום הקורס" },
          { participatnsCount: "מספר משתתפים" },
          { startDate: "תאריך התחלה" },
          { endDate: "תאריך סיום" },
        ]}
        onEditButton={(courseSummary) => {
          navigate(getPublicCourseSummaryPath(courseSummary.id));
        }}
      />
    </Screen>
  );
}
