import { useState } from "react";
import styles from "./LoginPage.module.css";
import { CustomRaisedButton } from "../Components/CustomComponents/CustomButtons";
import { useMsal } from "@azure/msal-react";
import { LOGIN_SCOPE } from "./AuthConfig";

export default function LoginPage() {
  const [error, setError] = useState("");
  const { instance } = useMsal();

  const login = () => {
    instance
      .loginPopup({
        scopes: [LOGIN_SCOPE],
        redirectUri: "/redirect",
      })
      .catch((error) => setError(error));
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Violet</div>

      <CustomRaisedButton label="התחבר" onClick={login} variant="contained" />

      <div>{error}</div>
    </div>
  );
}
