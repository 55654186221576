import styles from "./NewOrganizationPage.module.css";
import { useNavigate } from "react-router";
import { useState } from "react";
import Screen from "../../Components/Screen";
import { updateObject } from "../../Util/ObjectUpdater";
import OrderStatusStepper from "../OrderPage/OrderStatusStepper";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import { Path } from "../Path";
import { useDispatch, useSelector } from "react-redux";
import { addNewOrganizationIndex } from "../../Store/InitialData/Actions";
import { isDuplicateOrganizationId } from "../../Store/InitialData/Selectors";
import {
  OrganizationField,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { openDialog } from "../../Store/Appearance/Actions";
import { getMissingFields } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import EditableOrganizationSection from "../OrderPage/Sections/OrganizationSection/EditableOrganizationSection";
import { Organization } from "../../generated/Api";

const NEW_ORGANIZATION_REQUIRED_FIELDS = [
  OrganizationField.name,
  OrganizationField.referralWay,
];

export default function NewOrganizationPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { callApi } = useVioletApi();

  const [organization, setOrganization] = useState<Organization>(
    {} as Organization,
  );

  const duplicateOrganizationId = useSelector(isDuplicateOrganizationId)(
    organization.companyId,
  );

  const onSave = async () => {
    const missingFields = getMissingFields(
      organization,
      NEW_ORGANIZATION_REQUIRED_FIELDS,
    );
    if (missingFields.length > 0) {
      dispatch(
        openDialog(
          "שדות חובה חסרים:",
          missingFields.map(getFieldLabel).join(", "),
        ),
      );
      return;
    }

    callApi((api) =>
      api.organizations.createOrganization(organization).then((res) => {
        const newOrganization = res?.data;
        if (!newOrganization) return;
        navigate(Path.NewOrderForOrg + "/" + newOrganization.id);
        dispatch(
          addNewOrganizationIndex(
            newOrganization.id,
            newOrganization.name,
            newOrganization.companyId,
          ),
        );
      }),
    );
  };

  return (
    <Screen>
      <div className={styles.content}>
        <OrderStatusStepper />

        <EditableOrganizationSection
          organization={organization}
          requiredFields={NEW_ORGANIZATION_REQUIRED_FIELDS}
          isDuplicateOrganizationId={duplicateOrganizationId}
          onOrganizationChange={(field) => (value) => {
            setOrganization(
              updateObject(organization, {
                [field]: value,
              }),
            );
          }}
        >
          <div className={styles.saveOrganizationButton}>
            <CustomRaisedButton
              label="שמור ארגון חדש"
              onClick={onSave}
              variant="contained"
              disabled={!organization.name}
            />
          </div>
        </EditableOrganizationSection>
      </div>
    </Screen>
  );
}
