import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import styles from "./CustomRadioGroup.module.css";

interface CustomRadioGroupProps<Type> {
  title: string;
  value: Type;
  options: Type[];
  getLabel: (t: Type) => string;
  onSelected: (selected: Type) => void;
}

export default function CustomRadioGroup<Type>(
  props: CustomRadioGroupProps<Type>,
) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;

    const selectedOption = props.options.filter(
      (o) => props.getLabel(o) === selectedValue,
    )[0];
    props.onSelected(selectedOption);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.title}</div>
      <FormControl>
        <RadioGroup
          value={props.getLabel(props.value)}
          onChange={handleChange}
          row
        >
          {props.options.map((option) => (
            <FormControlLabel
              control={<Radio />}
              key={props.getLabel(option)}
              label={props.getLabel(option)}
              value={props.getLabel(option)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
