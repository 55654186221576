import MuiCheckbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";

interface TernarySelectorProps {
  value?: boolean;
  error?: boolean;
  title?: string;
  onChange: (newValue: boolean | undefined) => void;
}

export default function TernarySelector(props: TernarySelectorProps) {
  const onClick = () => {
    if (props.value === undefined) {
      props.onChange(true);
    } else if (props.value) {
      props.onChange(false);
    } else {
      props.onChange(undefined);
    }
  };

  return (
    <div>
      <MuiCheckbox
        checked={props.value !== undefined}
        value={props.value !== undefined}
        onChange={onClick}
        icon={<HelpIcon />}
        checkedIcon={props.value ? <CheckCircleIcon /> : <CancelIcon />}
        color={props.value ? "success" : "error"}
      />
      {props.title}
    </div>
  );
}
