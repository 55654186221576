import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import { Size } from "../../../../Util/Constants/Size";
import CustomTextField from "../../../../Components/CustomComponents/CustomTextField";
import CustomCheckbox from "../../../../Components/CustomComponents/CustomCheckbox";
import styles from "./CourseDetailsSection.module.css";
import { PublicCourseField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { updateObject } from "../../../../Util/ObjectUpdater";
import { PublicCourse } from "../../../../generated/Api";

export default function CourseDetailsSection(props: {
  publicCourse?: PublicCourse;
  setPublicCourse: React.Dispatch<React.SetStateAction<PublicCourse>>;
}) {
  const onChange = (key: PublicCourseField) => (value: string | boolean) => {
    props.setPublicCourse((publicCourse) =>
      updateObject(publicCourse, {
        [key]: value,
      }),
    );
  };

  return (
    <CustomSection title="פרטי הקורס">
      <div className={styles.section}>
        <div className={styles.row}>
          <CustomTextField
            field={PublicCourseField.courseName}
            valueObject={props.publicCourse}
            onChange={onChange(PublicCourseField.courseName)}
            size={Size.XL}
          />
          <CustomCheckbox
            field={PublicCourseField.onlineCourse}
            valueObject={props.publicCourse}
            onChange={onChange(PublicCourseField.onlineCourse)}
          />
          <CustomCheckbox
            field={PublicCourseField.isoPayed}
            valueObject={props.publicCourse}
            onChange={onChange(PublicCourseField.isoPayed)}
          />
        </div>

        {!props.publicCourse?.onlineCourse && (
          <>
            <div className={styles.row}>
              <CustomTextField
                field={PublicCourseField.courseCity}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.courseCity)}
              />
              <CustomTextField
                field={PublicCourseField.courseContactName}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.courseContactName)}
              />
              <CustomTextField
                field={PublicCourseField.courseContactPhone}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.courseContactPhone)}
              />
              <CustomTextField
                field={PublicCourseField.courseLocation}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.courseLocation)}
              />
              <CustomTextField
                field={PublicCourseField.mealCost}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.mealCost)}
              />
              <CustomTextField
                field={PublicCourseField.distanceCost}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.distanceCost)}
              />
            </div>
            <div className={styles.row}>
              <CustomCheckbox
                field={PublicCourseField.projector}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.projector)}
              />

              <CustomCheckbox
                field={PublicCourseField.soundSystem}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.soundSystem)}
              />
              <CustomCheckbox
                field={PublicCourseField.microphone}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.microphone)}
              />
              <CustomCheckbox
                field={PublicCourseField.parking}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.parking)}
              />
            </div>
            <div className={styles.row}>
              <CustomCheckbox
                field={PublicCourseField.roomsApproved}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.roomsApproved)}
              />

              <CustomCheckbox
                field={PublicCourseField.printedMaterials}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.printedMaterials)}
              />
              <CustomCheckbox
                field={PublicCourseField.printedCertificates}
                valueObject={props.publicCourse}
                onChange={onChange(PublicCourseField.printedCertificates)}
              />
            </div>
          </>
        )}
      </div>
    </CustomSection>
  );
}
