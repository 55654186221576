import { IDispatch } from "../../Interfaces/ReduxInterfaces";
import { InitialData, OfferedLecture } from "../../generated/Api";
import { openSnackbar } from "../Appearance/Actions";
import * as actionTypes from "./ActionTypes";

export function setInitialData(initialData: InitialData) {
  return {
    type: actionTypes.RECEIVE_INITIAL_DATA,
    payload: initialData,
  };
}

export function setAlertsCount(alertsCount: number) {
  return {
    type: actionTypes.SET_ALERTS_COUNT,
    payload: alertsCount,
  };
}

export function addNewOrganizationIndex(
  id: number,
  organizationName?: string,
  companyId?: string,
) {
  return {
    type: actionTypes.ADD_NEW_ORGANIZATION_INDEX,
    payload: {
      id,
      organizationName,
      companyId,
    },
  };
}

export function addNewOrderIndex(
  id: number,
  organizationId: number,
  organizationName?: string,
) {
  return {
    type: actionTypes.ADD_NEW_ORDER_INDEX,
    payload: {
      id,
      organizationId,
      status: "הצעת מחיר",
      organizationName,
    },
  };
}

export function addNewPublicCourseIndex(id: number, courseName: string) {
  return {
    type: actionTypes.ADD_NEW_PUBLIC_COURSE_INDEX,
    payload: {
      id,
      courseName,
    },
  };
}

export function updateOfferedLecture(
  offeredLectures: Record<string, OfferedLecture>,
  topic: string,
) {
  return async function updateOfferedLecture(dispatch: IDispatch) {
    dispatch(updateOfferedLecturesAction(offeredLectures));
    dispatch(openSnackbar("התבנית של " + topic.trim() + " נשמרה בהצלחה"));
  };
}

function updateOfferedLecturesAction(
  offeredLectures: Record<string, OfferedLecture>,
) {
  return {
    type: actionTypes.UPDATE_OFFERED_LECTURES,
    payload: offeredLectures,
  };
}
