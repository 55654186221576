import { useState } from "react";
import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import CustomDialog from "../../Components/CustomComponents/CustomDialog";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import { Organization } from "../../generated/Api";

export default function OrganizationsContactsTable(props: {
  organization: Organization;
  setOrganization: (org: Organization) => void;
}) {
  const { callApi } = useVioletApi();
  const [contactToDeleteIndex, setContactToDeleteIndex] = useState<
    number | undefined
  >();

  const contacts = (props.organization?.contacts || []).filter(
    (x) => !x.hidden,
  );

  const name =
    contactToDeleteIndex === undefined
      ? ""
      : contacts[contactToDeleteIndex!].firstName +
        " " +
        contacts[contactToDeleteIndex!].lastName;

  const deleteContact = async () => {
    if (contactToDeleteIndex === undefined) {
      return;
    }
    const updatedContact = {
      ...contacts[contactToDeleteIndex],
      hidden: true,
    };
    callApi((api) =>
      api.organizations
        .setContact({
          organizationId: props.organization.id,
          organizationContact: updatedContact,
        })
        .then((res) => {
          if (!res?.data) return;
          props.setOrganization(res.data.organization);
          setContactToDeleteIndex(undefined);
        }),
    );
  };

  return (
    <>
      <CustomPaperTable
        title="אנשי קשר"
        elements={contacts}
        tableHeaders={[
          { firstName: "שם פרטי" },
          { lastName: "שם משפחה" },
          { job: "תפקיד" },
          { email: "דואר אלקטרוני" },
          { phone1: "טלפון" },
          { phoneExtension: "שלוחה" },
          { phone2: "טלפון נוסף" },
          { delete: "מחק" },
        ]}
        onEditButton={() => {}}
        onDeleteButton={(contact) => setContactToDeleteIndex(contact)}
      />

      <CustomDialog
        open={contactToDeleteIndex !== undefined}
        onRequestClose={() => setContactToDeleteIndex(undefined)}
        title="האם ברצונך למחוק איש קשר?"
        actions={[
          <CustomRaisedButton
            key="close"
            label="ביטול"
            onClick={() => setContactToDeleteIndex(undefined)}
          />,
          <CustomRaisedButton
            key="delete"
            label="מחק"
            onClick={deleteContact}
            icon={<PersonRemoveIcon />}
            variant="contained"
          />,
        ]}
      >
        האם ברצונך למחוק את איש הקשר {name}
      </CustomDialog>
    </>
  );
}
