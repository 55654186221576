import { Size } from "../../../../../Util/Constants/Size";
import _ from "lodash";
import { CustomIconButton } from "../../../../../Components/CustomComponents/CustomButtons";
import CustomCheckbox from "../../../../../Components/CustomComponents/CustomCheckbox";
import styles from "./SinglePublicCourseParticipant.module.css";
import { PublicCourseParticipantField } from "../../../../../Store/Appearance/RequiredFields/FieldNames";
import Checkbox from "../../../../../Components/Checkbox";
import CustomTextField from "../../../../../Components/CustomComponents/CustomTextField";
import CalculateIcon from "@mui/icons-material/LocalAtm";
import EmailIcon from "@mui/icons-material/Email";
import { toPrintableDateFormat } from "../../../../../Util/TimeUtil";
import CustomAutoComplete from "../../../../../Components/CustomComponents/CustomAutoComplete";
import { toSuggestions } from "../../../../../Components/AutoComplete";
import CustomTrenarySelector from "../../../../../Components/CustomComponents/CustomTrenarySelector";
import {
  PublicCourseAttendanceAttendenceEnum as Attendence,
  PublicCourse,
  PublicCourseAttendance,
  PublicCourseParticipant,
} from "../../../../../generated/Api";

interface PublicCourseParticipantProps {
  participantIndex: number;
  participant: PublicCourseParticipant;
  publicCourse: PublicCourse;
  onChangeField: (
    key: PublicCourseParticipantField,
  ) => (value: string | boolean | PublicCourseAttendance[] | undefined) => void;
  requiredFields: PublicCourseParticipantField[];
}

const DESIRED_LECTURES = [
  "ק.ע.מ.פ. 9001",
  "ק.ע.מ.פ. 9100",
  "ק.ע.מ.פ. 13485",
  "ק.ע.מ.פ משולבות",
  "תקן 9001",
  "תקן 9100",
  "תקן 13485",
  "תקן משולבות",
  "הכרת 9001 + 9100",
  "הכרת 9001 + משולבות",
  "הכרת 9001 + 13485",
  "שדרוג לבכירים",
];

export default function SinglePublicCourseParticipant(
  props: PublicCourseParticipantProps,
) {
  const participant = props.participant;

  const onLectureCheck = (lectureId: number, isAttending: boolean) => {
    let attendingLectures = _.clone(participant.lecturesAttending);
    if (isAttending) {
      attendingLectures.push({
        publicCourseLectureId: lectureId,
        publicCourseParticipantId: props.participant.id,
        attendence: Attendence.UNSET,
      });
    } else {
      attendingLectures = attendingLectures.filter(
        (x) => x.publicCourseLectureId !== lectureId,
      );
    }

    props.onChangeField(PublicCourseParticipantField.lecturesAttending)(
      attendingLectures,
    );
  };

  const onCalculateParticipantCost = () => {
    const attendedLectures = _.filter(
      props.publicCourse.publicCourseLectures,
      (lecture) =>
        _.includes(
          participant.lecturesAttending.map((x) => x.publicCourseLectureId),
          lecture.id,
        ),
    );
    const cost = _.sum(
      _.map(attendedLectures, (lecture) => _.parseInt(lecture.price || "0")),
    );
    if (isNaN(cost)) {
      // Probably some lecture is missing a price.
      return;
    }
    props.onChangeField(PublicCourseParticipantField.participantCost)(
      cost + "",
    );
  };

  return (
    <div>
      <div className={styles.participantContent}>
        <div className={styles.participantFields}>
          <CustomTextField
            field={PublicCourseParticipantField.firstName}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(
              PublicCourseParticipantField.firstName,
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.lastName}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(
              PublicCourseParticipantField.lastName,
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.englishFirstName}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(
              PublicCourseParticipantField.englishFirstName,
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.englishLastName}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(
              PublicCourseParticipantField.englishLastName,
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.idNumber}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(
              PublicCourseParticipantField.idNumber,
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.phone}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(PublicCourseParticipantField.phone)}
          />
          <CustomTextField
            field={PublicCourseParticipantField.email}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(PublicCourseParticipantField.email)}
            size={Size.X2L}
          />
          <CustomIconButton
            onClick={() => window.open("mailto:" + participant.email)}
            tooltip="שלח מייל למשתתף"
            style={{ marginBottom: 10, marginLeft: 10 }}
          >
            <EmailIcon />
          </CustomIconButton>
          <CustomTextField
            field={PublicCourseParticipantField.job}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(PublicCourseParticipantField.job)}
            size={Size.XL}
          />
          <CustomIconButton
            onClick={onCalculateParticipantCost}
            tooltip="חשב עלות משתתף"
            style={{ marginBottom: 10, marginLeft: 10 }}
          >
            <CalculateIcon />
          </CustomIconButton>
          <CustomTextField
            field={PublicCourseParticipantField.participantCost}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(
              PublicCourseParticipantField.participantCost,
            )}
          />
          <CustomCheckbox
            field={PublicCourseParticipantField.isqMember}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(
              PublicCourseParticipantField.isqMember,
            )}
          />
        </div>
        <div className={styles.participantFields}>
          <CustomAutoComplete
            field={PublicCourseParticipantField.desiredLectures}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onTextChange={props.onChangeField(
              PublicCourseParticipantField.desiredLectures,
            )}
            suggestions={toSuggestions(DESIRED_LECTURES)}
            size={Size.X2L}
          />
        </div>
        <div className={styles.participantFields}>
          <CustomTrenarySelector
            field={PublicCourseParticipantField.certificateNeeded}
            valueObject={participant}
            requiredFields={props.requiredFields}
            onChange={props.onChangeField(
              PublicCourseParticipantField.certificateNeeded,
            )}
          />
          {participant.certificateNeeded && (
            <>
              <CustomTextField
                field={PublicCourseParticipantField.existingCertificates}
                valueObject={participant}
                size={Size.X2L}
                requiredFields={props.requiredFields}
                onChange={props.onChangeField(
                  PublicCourseParticipantField.existingCertificates,
                )}
              />
              <div />
              <CustomTextField
                field={PublicCourseParticipantField.certificateDetails}
                valueObject={participant}
                size={Size.X2L}
                requiredFields={props.requiredFields}
                onChange={props.onChangeField(
                  PublicCourseParticipantField.certificateDetails,
                )}
              />
              <CustomCheckbox
                field={PublicCourseParticipantField.certificateSent}
                valueObject={participant}
                requiredFields={props.requiredFields}
                onChange={props.onChangeField(
                  PublicCourseParticipantField.certificateSent,
                )}
              />
            </>
          )}
        </div>
        <div className={styles.lectures}>
          {_.map(
            props.publicCourse.publicCourseLectures,
            (publicCourseLecture) => {
              const isAttending = participant.lecturesAttending
                ? _.includes(
                    participant.lecturesAttending.map(
                      (x) => x.publicCourseLectureId,
                    ),
                    publicCourseLecture.id,
                  )
                : false;
              if (!publicCourseLecture.date) {
                console.warn(
                  "publicCourseLecture.date is undefined in public course lecture id: ",
                  publicCourseLecture.id,
                );
                return (
                  <Checkbox
                    value={isAttending}
                    label={"ללא תאריך - " + publicCourseLecture.topic}
                    onChange={(value: boolean) =>
                      onLectureCheck(publicCourseLecture.id, value)
                    }
                  />
                );
              }

              return (
                <div
                  data-lecture-id={publicCourseLecture.id}
                  key={props.participant.id + "-" + publicCourseLecture.id}
                >
                  <Checkbox
                    value={isAttending}
                    label={
                      toPrintableDateFormat(publicCourseLecture.date) +
                      " - " +
                      publicCourseLecture.topic
                    }
                    onChange={(value: boolean) =>
                      onLectureCheck(publicCourseLecture.id, value)
                    }
                  />
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
}
