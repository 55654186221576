import { isEmpty } from "../Util/StringUtil";
import classNames from "classnames";
import styles from "./CardIconField.module.scss";

interface CheckboxPropsProps {
  value?: string;
  icon: React.ReactNode;
  href?: (value: string) => string;
}

export default function ContactIconField(props: CheckboxPropsProps) {
  const value = props.value?.trim();
  if (isEmpty(value)) {
    return null;
  }

  return (
    <a
      href={props.href === undefined ? undefined : props.href!(value!)}
      className={classNames({
        [styles.conatctIconField]: true,
        [styles.pointer]: props.href!!,
      })}
    >
      {props.icon}
      <div className={styles.fieldValue}>{value}</div>
    </a>
  );
}
