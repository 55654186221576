export enum OrganizationField {
  name = "name",
  address = "address",
  city = "city",
  postalCode = "postalCode",
  companyId = "companyId",
  paymentConditions = "paymentConditions",
  externalInvoiceHandler = "externalInvoiceHandler",
  referralWay = "referralWay",
  referralWayDetails = "referralWayDetails",
  internalOrderIdRequired = "internalOrderIdRequired",
  notes = "notes",
}

export enum ContacField {
  firstName = "firstName",
  lastName = "lastName",
  phone1 = "phone1",
  phone2 = "phone2",
  email = "email",
  job = "job",
  phoneExtension = "phoneExtension",
}

export enum OrderField {
  status = "status",
  contactId = "contactId",
  financialContactId = "financialContactId",

  street = "street",
  streetNumber = "streetNumber",
  city = "city",
  location = "location",
  audienceType = "audienceType",
  daySchedule = "daySchedule",

  projector = "projector",
  soundSystem = "soundSystem",
  microphone = "microphone",
  parking = "parking",
  approved = "approved",
  sameAudience = "sameAudience",
  rejected = "rejected",
  rejectionReason = "rejectionReason",
  rejectionDetails = "rejectionDetails",
  cancelled = "cancelled",
  cancellationReason = "cancellationReason",
  cancellationDetails = "cancellationDetails",

  // Public course participants
  publicCourseId = "publicCourseId",

  // Follow up
  followUpRequired = "followUpRequired",
  followUpDate = "followUpDate",
  followUpDetails = "followUpDetails",

  // Payment
  cost = "cost",
  oneWayDistance = "oneWayDistance",
  travelExpenses = "travelExpenses",
  extraCosts = "extraCosts",
  sum = "sum",
  vat = "vat",
  totalSum = "totalSum",

  // Invoice
  proformaInvoiceNumber = "proformaInvoiceNumber",
  proformaInvoiceDate = "proformaInvoiceDate",
  expectedPayDate = "expectedPayDate",
  internalOrderNumber = "internalOrderNumber",
  externalInvoiceSent = "externalInvoiceSent",
  taxInvoiceNumber = "taxInvoiceNumber",
  taxInvoiceDate = "taxInvoiceDate",
  receiptNumber = "receiptNumber",

  // Notes
  notes = "notes",

  // Not editable fields
  id = "id",
  organizationId = "organizationId",
  changedDate = "changedDate",
  createdDate = "createdDate",
}

export enum LectureTimeField {
  isOnline = "isOnline",
  topic = "topic",
  tie = "tie",
  date = "date",
  startTime = "startTime",
  endTime = "endTime",
  duration = "duration",
  audienceSize = "audienceSize",
  travelTime = "travelTime",
  videoCallLink = "videoCallLink",
  offerDate = "offerDate",
  subject = "subject",
  content = "content",
  offerCost = "offerCost",
  noTravelCost = "noTravelCost",
  paymentConditionInfo = "paymentConditionInfo",
  wakeUpTime = "wakeUpTime",
  leaveHomeTime = "leaveHomeTime",
  arriveTime = "arriveTime",
  lectureStartTime = "lectureStartTime",
  approvalTime = "approvalTime",
}

export enum PublicCourseField {
  courseName = "courseName",
  onlineCourse = "onlineCourse",
  courseLocation = "courseLocation",
  courseContactName = "courseContactName",
  courseContactPhone = "courseContactPhone",
  courseCity = "courseCity",
  mealCost = "mealCost",
  distanceCost = "distanceCost",
  isoFee = "isoFee",
  isoPayed = "isoPayed",
  roomsApproved = "roomsApproved",
  printedCertificates = "printedCertificates",
  printedMaterials = "printedMaterials",
  projector = "projector",
  soundSystem = "soundSystem",
  microphone = "microphone",
  parking = "parking",
}

export enum PublicCourseLectureField {
  topic = "topic",
  tie = "tie",
  date = "date",
  startTime = "startTime",
  endTime = "endTime",
  duration = "duration",
  price = "price",
  pages = "pages",
  roomCost = "roomCost",
  videoCallLink = "videoCallLink",
  guestLecturer = "guestLecturer",
  guestLecturerName = "guestLecturerName",
  guestLecturerCost = "guestLecturerCost",
}

export enum PublicCourseParticipantField {
  firstName = "firstName",
  lastName = "lastName",
  englishFirstName = "englishFirstName",
  englishLastName = "englishLastName",
  idNumber = "idNumber",
  phone = "phone",
  email = "email",
  job = "job",
  participantCost = "participantCost",
  isqMember = "isqMember",
  lecturesAttending = "lecturesAttending",
  certificateNeeded = "certificateNeeded",
  existingCertificates = "existingCertificates",
  certificateDetails = "certificateDetails",
  certificateSent = "certificateSent",
  desiredLectures = "desiredLectures",
}

export enum CourseLecturesInstanceFiled {
  topic = "topic",
  tie = "tie",
}

export function getFieldLabel(field: Field) {
  switch (field) {
    case OrderField.status:
      return "סטאטוס";
    case OrderField.contactId:
      return "איש קשר";
    case OrderField.financialContactId:
      return "איש קשר לתשלום";
    case ContacField.firstName:
      return "שם פרטי";
    case ContacField.lastName:
      return "שם משפחה";
    case ContacField.phone1:
      return "טלפון";
    case ContacField.phone2:
      return "טלפון נוסף";
    case ContacField.email:
      return "דואר אלקטרוני";
    case ContacField.job:
      return "תפקיד";
    case ContacField.phoneExtension:
      return "שלוחה";
    case OrderField.street:
      return "רחוב";
    case OrderField.streetNumber:
      return "מספר";
    case OrderField.city:
      return "עיר";
    case OrderField.location:
      return "מיקום";
    case OrderField.audienceType:
      return "קהל יעד";
    case OrderField.daySchedule:
      return "מהות היום + לו״ז";
    case OrderField.projector:
      return "מקרן";
    case OrderField.soundSystem:
      return "מערכת הגברה";
    case OrderField.microphone:
      return "מיקרופון דש";
    case OrderField.parking:
      return "חניה";
    case OrderField.approved:
      return "הזמנה אושרה";
    case OrderField.sameAudience:
      return "קהל יעד זהה";
    case OrderField.rejected:
      return "הזמנה לא יצאה לפועל";
    case OrderField.rejectionReason:
      return "סיבת דחיה";
    case OrderField.rejectionDetails:
      return "פרטי הדחיה";
    case OrderField.cancelled:
      return "הזמנה בוטלה";
    case OrderField.cancellationReason:
      return "סיבת ביטול";
    case OrderField.cancellationDetails:
      return "פרטי ביטול";
    case OrderField.followUpRequired:
      return "נדרש המשך טיפול";
    case OrderField.followUpDate:
      return "תאריך המשך טיפול";
    case OrderField.followUpDetails:
      return "פרטים";
    case OrderField.cost:
      return "מחיר הרצאות";
    case OrderField.oneWayDistance:
      return "מרחק כיוון אחד";
    case OrderField.travelExpenses:
      return "עלות נסיעות";
    case OrderField.extraCosts:
      return "עלויות נוספות";
    case OrderField.sum:
      return "סכום לפני מע״מ";
    case OrderField.vat:
      return "מע״מ";
    case OrderField.totalSum:
      return "סה״כ לתשלום";
    case OrderField.proformaInvoiceNumber:
      return "מספר חשבונית עסקה";
    case OrderField.proformaInvoiceDate:
      return "תאריך חשבונית עסקה";
    case OrderField.expectedPayDate:
      return "תאריך לתשלום";
    case OrderField.internalOrderNumber:
      return "מספר הזמנת רכש";
    case OrderField.externalInvoiceSent:
      return "נשלחה חשבונית עסקה לחברה חיצונית";
    case OrderField.taxInvoiceNumber:
      return "מספר חשבונית מס";
    case OrderField.taxInvoiceDate:
      return "תאריך חשבונית מס";
    case OrderField.receiptNumber:
      return "מספר קבלה";
    case OrderField.notes:
      return "הערות";
    case OrderField.id:
      return "הזמנה מספר";
    case OrderField.organizationId:
      return "ארגון";
    case OrderField.publicCourseId:
      return "בחר קורס";
    case OrderField.createdDate:
      return "תאריך יצירה";
    case OrderField.changedDate:
      return "תאריך שינוי";

    case OrganizationField.name:
      return "שם הארגון";
    case OrganizationField.address:
      return "כתובת הארגון";
    case OrganizationField.city:
      return "עיר";
    case OrganizationField.postalCode:
      return "מיקוד";
    case OrganizationField.companyId:
      return "ח.פ / ע.מ";
    case OrganizationField.paymentConditions:
      return "תנאי תשלום";
    case OrganizationField.externalInvoiceHandler:
      return "חברת חשבוניות חיצונית";
    case OrganizationField.referralWay:
      return "איך הגיעו אלינו";
    case OrganizationField.referralWayDetails:
      return "פרטי ההגעה";
    case OrganizationField.internalOrderIdRequired:
      return "נדרשת הזמנת רכש";
    case OrganizationField.notes:
      return "הערות לארגון";

    case LectureTimeField.isOnline:
      return "הרצאה מקוונת";
    case LectureTimeField.topic:
      return "נושא";
    case LectureTimeField.tie:
      return "עניבה";
    case LectureTimeField.date:
      return "תאריך";
    case LectureTimeField.startTime:
      return "התחלה";
    case LectureTimeField.endTime:
      return "סיום";
    case LectureTimeField.duration:
      return "משך";
    case LectureTimeField.audienceSize:
      return "מס׳ משתתפים";
    case LectureTimeField.travelTime:
      return "זמן נסיעה";
    case LectureTimeField.videoCallLink:
      return "קישור לשיחה";
    case LectureTimeField.offerDate:
      return "תאריך הצעה";
    case LectureTimeField.subject:
      return "כותרת";
    case LectureTimeField.content:
      return "תוכן";
    case LectureTimeField.offerCost:
      return "עלות";
    case LectureTimeField.noTravelCost:
      return "ללא עלות נסיעות";
    case LectureTimeField.paymentConditionInfo:
      return "תנאי תשלום";
    case LectureTimeField.wakeUpTime:
      return "זמן לקום";
    case LectureTimeField.leaveHomeTime:
      return "יציאה מהבית";
    case LectureTimeField.arriveTime:
      return "הגעה למקום";
    case LectureTimeField.lectureStartTime:
      return "תחילת ההרצאה";
    case LectureTimeField.approvalTime:
      return "תאריך אישור הזמנה";

    case PublicCourseField.courseName:
      return "שם הקורס";
    case PublicCourseField.courseContactName:
      return "איש קשר";
    case PublicCourseField.courseContactPhone:
      return "טלפון";
    case PublicCourseField.courseCity:
      return "עיר";
    case PublicCourseField.courseLocation:
      return "מיקום הקורס";
    case PublicCourseField.mealCost:
      return "עלות ארוחה";
    case PublicCourseField.distanceCost:
      return "עלות נסיעות";
    case PublicCourseField.onlineCourse:
      return "קורס מקוון";
    case PublicCourseField.isoFee:
      return "תשלום לאיגוד";
    case PublicCourseField.isoPayed:
      return "שולם לאיגוד";
    case PublicCourseField.roomsApproved:
      return "חדרים אושרו";
    case PublicCourseField.printedMaterials:
      return "חוברות הודפסו";
    case PublicCourseField.printedCertificates:
      return "תעודות הודפסו";

    case PublicCourseParticipantField.firstName:
      return "שם פרטי";
    case PublicCourseParticipantField.lastName:
      return "שם משפחה";
    case PublicCourseParticipantField.englishFirstName:
      return "שם פרטי באנגלית";
    case PublicCourseParticipantField.englishLastName:
      return "שם משפחה באנגלית";
    case PublicCourseParticipantField.idNumber:
      return "תעודת זהות";
    case PublicCourseParticipantField.phone:
      return "טלפון";
    case PublicCourseParticipantField.email:
      return "דואר אלקטרוני";
    case PublicCourseParticipantField.job:
      return "תפקיד";
    case PublicCourseParticipantField.participantCost:
      return "מחיר השתתפות";
    case PublicCourseParticipantField.isqMember:
      return "חבר איגוד";
    case PublicCourseParticipantField.certificateNeeded:
      return "נדרשת תעודה";
    case PublicCourseParticipantField.existingCertificates:
      return "תעודות קיימות";
    case PublicCourseParticipantField.certificateDetails:
      return "תעודה מבוקשת";
    case PublicCourseParticipantField.certificateSent:
      return "נשלחה תעודה";
    case PublicCourseParticipantField.desiredLectures:
      return "הרצאות מבוקשות";
    case PublicCourseParticipantField.lecturesAttending:
      return "הרצאות";

    case PublicCourseLectureField.pages:
      return "עמודים בחוברת";
    case PublicCourseLectureField.roomCost:
      return "עלות החדר";
    case PublicCourseLectureField.price:
      return "מחיר ללקוח";
    case PublicCourseLectureField.guestLecturer:
      return "מרצה אורח";
    case PublicCourseLectureField.guestLecturerName:
      return "שם מרצה אורח";
    case PublicCourseLectureField.guestLecturerCost:
      return "עלות מרצה אורח";
    default:
      throw Error("Missing field labeld for: " + field);
  }
}

export type Field =
  | OrderField
  | ContacField
  | OrganizationField
  | LectureTimeField
  | CourseLecturesInstanceFiled
  | PublicCourseField
  | PublicCourseParticipantField
  | PublicCourseLectureField;
