import {
  Field,
  OrderField,
  OrganizationField,
} from "../../../Store/Appearance/RequiredFields/FieldNames";
import { isEmpty } from "../../../Util/StringUtil";
import { calculateOrderRequiredFields } from "./RequiredFieldsByStatus";
import {
  getMissingFields,
  getElementInArrayMissingFields,
} from "../../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import {
  Order,
  OrderLecture,
  OrderStatusEnum,
  Organization,
  PublicCourseParticipant,
} from "../../../generated/Api";

export function getOrderMissingRequiredFields(
  order: Order,
  orderLectures: OrderLecture[],
  organization: Organization,
  publicCourseParticipants: PublicCourseParticipant[],
): Field[] {
  const requiredFields = calculateOrderRequiredFields(
    order.status,
    organization,
    orderLectures,
    order.followUpRequired,
  );
  if (
    requiredFields.order.includes(OrderField.externalInvoiceSent) &&
    !order.externalInvoiceSent
  ) {
    return [OrderField.externalInvoiceSent];
  }
  const orderMissingFields = getMissingFields(order, requiredFields.order);
  if (orderMissingFields.length > 0) {
    return orderMissingFields;
  }

  if (isPublicCourseOrder(order)) {
    const publicCourseParticipantsMissingFields =
      getElementInArrayMissingFields(
        publicCourseParticipants,
        requiredFields.publicCourseParticipant,
      );
    if (publicCourseParticipantsMissingFields.length > 0) {
      return publicCourseParticipantsMissingFields;
    }
  }

  if (isInternalCourse(order)) {
    const lectureMissingFields = orderLectures
      .map((lecture) =>
        lecture.isOnline
          ? getMissingFields(lecture, requiredFields.onlineCourseLectureTime)
          : getMissingFields(lecture, requiredFields.internalOrderLectureTime),
      )
      .filter((x) => x.length > 0);
    if (lectureMissingFields.length > 0) {
      return lectureMissingFields[0];
    }
  }

  return [];
}

const APPROVED_ORDER_REQUIRED_ORGANIZATION_FIELDS = [
  OrganizationField.companyId,
  OrganizationField.paymentConditions,
];

export function organizationHasMissingFields(
  status: OrderStatusEnum,
  organization: Organization,
): boolean {
  switch (status) {
    case OrderStatusEnum.Offer:
    case OrderStatusEnum.Cancelled:
    case OrderStatusEnum.Rejected:
      return false;
    case OrderStatusEnum.ApprovedOrder:
    case OrderStatusEnum.IsExecuting:
    case OrderStatusEnum.Executed:
    case OrderStatusEnum.WaitingPayment:
    case OrderStatusEnum.Payed:
    // fall-through
  }
  if (
    isEmpty(organization.companyId) ||
    isEmpty(organization.paymentConditions)
  ) {
    const missingFields = getMissingFields(
      organization,
      APPROVED_ORDER_REQUIRED_ORGANIZATION_FIELDS,
    );
    return missingFields.length > 0;
  }

  return false;
}

function isPublicCourseOrder(order: Order) {
  return order.isPublicCourseOrder;
}

function isInternalCourse(order: Order) {
  return !order.isPublicCourseOrder;
}
