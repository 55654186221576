import { useNavigate } from "react-router";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import styles from "./AppMenu.module.scss";
import List from "@mui/material/List";
import { Path } from "../Pages/Path";
import MenuItems from "./MenuItems";
import { useMsal } from "@azure/msal-react";
import { isMobileScreen } from "./MobileViewContext";

export const APP_VERSION = process.env.REACT_APP_VERSION || "dev";

export default function AppMenu() {
  const navigate = useNavigate();
  const { instance } = useMsal();

  if (isMobileScreen()) {
    return null;
  }
  const appTitle = "שיא האיכות - Violet";

  return (
    <div className={styles.largeScreenSideMenu}>
      <List className={styles.list}>
        <ListItem
          disablePadding
          onClick={() => navigate(Path.Home)}
          className={styles.appName}
        >
          <ListItemButton>
            <div className={styles.appNameButton}>{appTitle}</div>
          </ListItemButton>
        </ListItem>

        <MenuItems />
      </List>

      <div className={styles.appVersion}>
        {APP_VERSION} - {instance.getActiveAccount()?.name}
      </div>
    </div>
  );
}
