import { IOption } from "../../../../Components/CustomComponents/IOption";
import SelectField from "../../../../Components/SelectField";
import {
  Field,
  getFieldLabel,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { Size } from "../../../../Util/Constants/Size";
import { OnOrderChangeFunction } from "../../OrderPage";
import { shouldMarkWithError } from "../../../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import { Order } from "../../../../generated/Api";

interface OrderSelectFieldProps {
  field: OrderField;
  order: Order;
  requiredFields: Field[];
  onOrderChange: OnOrderChangeFunction;
  fullWidth?: boolean;
  options: IOption[];
  size?: Size;
}

export default function OrderSelectField(props: OrderSelectFieldProps) {
  const value = props.order[props.field] as string;
  return (
    <SelectField
      label={getFieldLabel(props.field)}
      value={value}
      onChange={props.onOrderChange(props.field)}
      error={shouldMarkWithError(props.field, props.requiredFields, value)}
      fullWidth={props.fullWidth}
      options={props.options}
      size={props.size}
    />
  );
}
