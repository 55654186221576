import CloseIcon from "@mui/icons-material/Close";
import { CustomIconButton } from "../../../../../../Components/CustomComponents/CustomButtons";
import { Alert } from "@mui/material";
import styles from "./OfferApprovalInformation.module.css";
import { toPrintableDateFormat } from "../../../../../../Util/TimeUtil";
import { OrderLecture } from "../../../../../../generated/Api";

interface OfferApprovalInformationProps {
  lectureTimeIndex: number;
  lectureTime: OrderLecture;
  removeOfferApproval: (lectureId: number) => void;
}

export default function OfferApprovalInformation(
  props: OfferApprovalInformationProps,
) {
  if (!props.lectureTime.approvalTime) return null;

  const boldedText = `הזמנה זו אושרה על ידי ${
    props.lectureTime.approvingUserFirstName
  } ${props.lectureTime.approvingUserLastName} בתאריך ${toPrintableDateFormat(
    props.lectureTime.approvalTime,
  )}. `;

  const text = `עבור הרצאה בתאריכים ${props.lectureTime.lectureDates}. פרטי המאשר: ${props.lectureTime.approvingUserRole}, ${props.lectureTime.approvingOrganization}, ${props.lectureTime.approvingUserPhone}, ${props.lectureTime.approvingUserEmail}`;
  return (
    <Alert
      severity="success"
      action={
        <CustomIconButton
          onClick={() => props.removeOfferApproval(props.lectureTime.id)}
          tooltip={"מחק"}
        >
          <CloseIcon />
        </CustomIconButton>
      }
      className={styles.alert}
    >
      <div className={styles.text}>
        <div className={styles.bold}>{boldedText}</div>
        <div>{text}</div>
      </div>
    </Alert>
  );
}
