import * as _ from "lodash";

export interface IOption {
  key: string | number;
  label: string;
  disabled?: boolean;
}

export function createOptions(labels: string[]): IOption[] {
  return _.map(labels, (label) => ({
    key: label,
    label,
  }));
}
