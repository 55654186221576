import CustomPaperTable from "../Components/Table/CustomPaperTable";
import { getOrderPath } from "./Path";
import { useNavigate } from "react-router";
import SideMenu from "../Components/SideMenu";
import Screen from "../Components/Screen";
import { useEffect, useState } from "react";
import { useVioletApi } from "../Auth/useFetchWithMsal";
import { PaymentExpectedOrder } from "../generated/Api";

export default function ExpectedIncomePage() {
  const navigate = useNavigate();
  const { callApi, isLoading } = useVioletApi();

  const [ordersSummary, setOrderssSummary] = useState<PaymentExpectedOrder[]>(
    [],
  );
  const [totalSum, setTotalSum] = useState("");

  useEffect(() => {
    callApi((api) =>
      api.orders
        .getPaymentExpectedOrders({ includeApprovedOrders: true })
        .then((res) => {
          if (!res.data) {
            return;
          }
          setOrderssSummary(res.data.orders);
          setTotalSum(res.data.totalSum);
        }),
    );
  }, [callApi]);

  const sideMenu = <SideMenu title={"סה״כ צבר הזמנות: " + totalSum} />;

  return (
    <Screen sideMenu={sideMenu} isLoading={isLoading}>
      <CustomPaperTable
        title="הזמנות מאושרות"
        elements={ordersSummary}
        tableHeaders={[
          { id: "מספר הזמנה" },
          { organizationName: "שם הארגון" },
          { lectureDate: "תאריך הרצאה" },
          { topic: "נושא" },
          { status: "סטאטוס" },
          { expectedPayDate: "תאריך תשלום" },
          { totalSum: "סכום לתשלום" },
        ]}
        onEditButton={(summary) => {
          navigate(getOrderPath(summary.id));
        }}
      />
    </Screen>
  );
}
