import _ from "lodash";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styles from "./OrderStatusStepper.module.css";
import { progressiveStatuses, getStatusLabel } from "../../Interfaces/Status";
import { OrderStatusEnum } from "../../generated/Api";

export default function OrderStatusStepper(props: {
  status?: OrderStatusEnum;
}) {
  const orderStatus = props.status || OrderStatusEnum.Offer;

  return (
    <div className={styles.stepperContainer}>
      <Stepper className={styles.stepper}>
        {_.map(progressiveStatuses, (status: string) => (
          <Step key={status} active={status === orderStatus}>
            <StepLabel>{getStatusLabel(status as OrderStatusEnum)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
