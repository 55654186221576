import {
  LectureTimeField,
  OrderField,
  PublicCourseParticipantField,
} from "../../../Store/Appearance/RequiredFields/FieldNames";
import {
  IRequiredFields,
  mergerRequiredFields,
} from "../../../Store/Appearance/RequiredFields/Util";
import _ from "lodash";
import {
  OrderLecture,
  OrderStatusEnum,
  Organization,
} from "../../../generated/Api";

const offer: IRequiredFields = {
  order: [OrderField.contactId],
  internalOrderLectureTime: [LectureTimeField.topic],
  onlineCourseLectureTime: [LectureTimeField.topic],
  internalOrder: [],
  publicCourseParticipant: [
    PublicCourseParticipantField.firstName,
    PublicCourseParticipantField.lastName,
  ],
};

const approvedOrder = mergerRequiredFields(offer, {
  order: [
    OrderField.financialContactId,
    OrderField.cost,
    OrderField.totalSum,
    OrderField.internalOrderNumber,
  ],
  internalOrder: [
    OrderField.street,
    OrderField.city,
    OrderField.parking,
    OrderField.projector,
    OrderField.soundSystem,
    OrderField.microphone,
  ],
  internalOrderLectureTime: [
    LectureTimeField.date,
    LectureTimeField.startTime,
    LectureTimeField.endTime,
    LectureTimeField.duration,
  ],
  onlineCourseLectureTime: [
    LectureTimeField.date,
    LectureTimeField.startTime,
    LectureTimeField.endTime,
    LectureTimeField.duration,
    LectureTimeField.videoCallLink,
  ],
  publicCourseParticipant: [
    PublicCourseParticipantField.firstName,
    PublicCourseParticipantField.lastName,
    PublicCourseParticipantField.idNumber,
    PublicCourseParticipantField.phone,
    PublicCourseParticipantField.email,
    PublicCourseParticipantField.certificateNeeded,
  ],
});

const isExecuting = mergerRequiredFields(approvedOrder);

const executed = mergerRequiredFields(isExecuting);

const waitingPayment = mergerRequiredFields(executed, {
  order: [OrderField.expectedPayDate, OrderField.externalInvoiceSent],
});

const payed = mergerRequiredFields(waitingPayment);

const cancelled: IRequiredFields = {
  order: [OrderField.cancellationReason],
  internalOrderLectureTime: [],
  onlineCourseLectureTime: [],
  internalOrder: [],
  publicCourseParticipant: [],
};

const rejected: IRequiredFields = {
  order: [OrderField.rejectionReason],
  internalOrderLectureTime: [],
  onlineCourseLectureTime: [],
  internalOrder: [],
  publicCourseParticipant: [],
};

export const followUpRequiredRequiredFields: IRequiredFields = {
  order: [OrderField.followUpDate, OrderField.followUpDetails],
  internalOrderLectureTime: [],
  onlineCourseLectureTime: [],
  internalOrder: [],
  publicCourseParticipant: [],
};

export function getRequiredFieldsByStatus(
  status: OrderStatusEnum,
): IRequiredFields {
  if (!status) {
    return {
      order: [],
      internalOrderLectureTime: [],
      onlineCourseLectureTime: [],
      internalOrder: [],
      publicCourseParticipant: [],
    };
  }

  switch (status) {
    case OrderStatusEnum.Offer:
      return offer;
    case OrderStatusEnum.ApprovedOrder:
      return approvedOrder;
    case OrderStatusEnum.IsExecuting:
      return isExecuting;
    case OrderStatusEnum.Executed:
      return executed;
    case OrderStatusEnum.WaitingPayment:
      return waitingPayment;
    case OrderStatusEnum.Payed:
      return payed;
    case OrderStatusEnum.Cancelled:
      return cancelled;
    case OrderStatusEnum.Rejected:
      return rejected;
  }
}

export function calculateOrderRequiredFields(
  status: OrderStatusEnum,
  organization: Organization,
  orderLectures: OrderLecture[],
  followUpRequired: boolean,
): IRequiredFields {
  if (followUpRequired) {
    return followUpRequiredRequiredFields;
  }

  let requiredFieldsByStatus = getRequiredFieldsByStatus(status);
  let orderFields = [...requiredFieldsByStatus.order];
  if (!organization.internalOrderIdRequired) {
    orderFields = _.without(orderFields, OrderField.internalOrderNumber);
  }
  if (!organization.externalInvoiceHandler) {
    orderFields = _.without(orderFields, OrderField.externalInvoiceSent);
  }
  if (orderLectures.filter((x) => !x.isOnline).length > 0) {
    // Has at least one onsite lecture.
    orderFields = [...orderFields, ...requiredFieldsByStatus.internalOrder];
  }
  return {
    ...requiredFieldsByStatus,
    order: orderFields,
  };
}
