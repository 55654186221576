import { Field, LectureTimeField, OrderField } from "./FieldNames";
import _ from "lodash";

const NUMERIC_FIELDS_TO_CHECK = [
  OrderField.contactId,
  OrderField.financialContactId,
];

const DATE_FIELDS_TO_CHECK = new Set<Field>();
DATE_FIELDS_TO_CHECK.add(OrderField.followUpDate);
DATE_FIELDS_TO_CHECK.add(OrderField.proformaInvoiceDate);
DATE_FIELDS_TO_CHECK.add(OrderField.expectedPayDate);
DATE_FIELDS_TO_CHECK.add(OrderField.taxInvoiceDate);
DATE_FIELDS_TO_CHECK.add(LectureTimeField.date);
DATE_FIELDS_TO_CHECK.add(LectureTimeField.offerDate);

export function shouldMarkWithError(
  field: Field,
  requiredFields?: Field[],
  value?: any,
) {
  if (!requiredFields || !_.includes(requiredFields, field)) {
    return false;
  }

  return !value || !isValidDateValue(field, value);
}

export function getMissingFields(object: any, required: Field[]) {
  const nonEmptyKeys = _.filter(
    _.keys(object),
    (key) => object[key] !== "" && object[key] !== undefined,
  );
  const res = _.difference(required, nonEmptyKeys) as Field[];

  _.forEach(NUMERIC_FIELDS_TO_CHECK, (numbericField) => {
    if (required.includes(numbericField)) {
      if (!object[numbericField] || object[numbericField] <= 0) {
        res.push(numbericField);
      }
    }
  });

  DATE_FIELDS_TO_CHECK.forEach((dateField) => {
    if (required.includes(dateField)) {
      if (
        !object[dateField] ||
        !isValidDateValue(dateField, object[dateField])
      ) {
        res.push(dateField);
      }
    }
  });

  return _.uniq(res);
}

export function getElementInArrayMissingFields(
  array: object[],
  requiredFields: Field[],
): Field[] {
  for (let i = 0; i < _.size(array); i++) {
    const missingFields = getMissingFields(array[i], requiredFields);
    if (missingFields.length > 0) {
      return missingFields;
    }
  }
  return [];
}

function isValidDateValue(field: Field, dateValue: string) {
  if (!DATE_FIELDS_TO_CHECK.has(field)) {
    return true;
  }
  // Year starts with 20.
  return _.startsWith(dateValue, "20");
}
