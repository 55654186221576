import App from "./App";
import OrderPage from "./Pages/OrderPage/OrderPage";
import EditPublicCoursePage from "./Pages/PublicCourse/EditPublicCoursePage";
import OrganizationPage from "./Pages/OrganizationPage/OrganizationPage";
import WaitingPaymentPage from "./Pages/WaitingPaymentPage";
import ExpectedIncomePage from "./Pages/ExpectedIncomePage";
import FutureLecturesPage from "./Pages/FutureLecturesPage";
import AllOrdersPage from "./Pages/AllOrdersPage/AllOrdersPage";
import BiPage from "./Pages/BiPage/BiPage";
import AllPublicCoursesPage from "./Pages/PublicCourse/AllPublicCoursesPage";
import ActionRequiredPage from "./Pages/ActionRequiredPage";
import { Path } from "./Pages/Path";
import OfferTemplatesPage from "./Pages/OfferTemplatesPage/OfferTemplatesPage";
import UploadFilesPage from "./Pages/UploadContentPage";
import {
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";
import SearchPage from "./Pages/Search/SearchPage";
import { Navigate, Outlet } from "react-router";
import PhonePage from "./Pages/PhonePage";
import YaronLecturesPage from "./Pages/YaronLecturesPage";
import PublicCourseSummaryPage from "./Pages/PublicCourse/PublicCoursePage/PublicCourseSummaryPage";
import NewOrderPage from "./Pages/OrderPage/NewOrderPage";
import NewOrganizationPage from "./Pages/OrganizationPage/NewOrganizationPage";
import NewOrderForOrganizationPage from "./Pages/OrderPage/NewOrderForOrganizationPage";
import PublicCourseAttendancePage from "./Pages/PublicCourse/PublicCourseAttendancePage";
import PublicCourseTestScoresPage from "./Pages/PublicCourse/PublicCourseTestScoresPage";
import OpenPublicCourseOrdersPage from "./Pages/PublicCourse/OpenPublicCourseOrdersPage";
import AdminPage from "./Pages/Admin/AdminPage";

export default function Routes() {
  const router = createHashRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <App>
            <Outlet />
          </App>
        }
      >
        <Route path={Path.Search} element={<SearchPage />} />
        <Route path={Path.Search + "/:q"} element={<SearchPage />} />
        <Route path={Path.NewOrder} element={<NewOrderPage />} />
        <Route
          path={Path.NewOrderForOrg + "/:organizationId"}
          element={<NewOrderForOrganizationPage />}
        />
        <Route path={Path.NewOrganization} element={<NewOrganizationPage />} />
        <Route path={Path.Order + "/:orderId"} element={<OrderPage />} />
        <Route path={Path.Phone} element={<PhonePage />} />
        <Route
          path={Path.EditPublicCourse}
          element={<EditPublicCoursePage />}
        />
        <Route
          path={Path.EditPublicCourse + "/:courseId"}
          element={<EditPublicCoursePage />}
        />
        <Route
          path={Path.PublicCourseSummary + "/:courseId"}
          element={<PublicCourseSummaryPage />}
        />
        <Route
          path={Path.PublicCourseAttendance + "/:courseId"}
          element={<PublicCourseAttendancePage />}
        />
        <Route
          path={Path.PublicCourseTestScores + "/:courseId"}
          element={<PublicCourseTestScoresPage />}
        />
        <Route
          path={Path.Organization + "/:organizationId"}
          element={<OrganizationPage />}
        />
        <Route path={Path.ActionRequired} element={<ActionRequiredPage />} />
        <Route
          path={Path.ActionRequired + "/:filters"}
          element={<ActionRequiredPage />}
        />
        <Route path={Path.WaitingPayment} element={<WaitingPaymentPage />} />
        <Route path={Path.ExpectedIncome} element={<ExpectedIncomePage />} />
        <Route path={Path.FutureLectures} element={<FutureLecturesPage />} />
        <Route path={Path.AllOrders} element={<AllOrdersPage />} />
        <Route
          path={Path.AllPublicCourses}
          element={<AllPublicCoursesPage />}
        />
        <Route
          path={Path.OpenPublicCourseOrders}
          element={<OpenPublicCourseOrdersPage />}
        />
        <Route path={Path.OfferTemplates} element={<OfferTemplatesPage />} />
        <Route path={Path.UploadFiles} element={<UploadFilesPage />} />
        <Route path={Path.Bi} element={<BiPage />} />
        <Route path={Path.Yaron} element={<YaronLecturesPage />} />
        <Route path={Path.Admin} element={<AdminPage />} />

        <Route path="*" element={<Navigate to={Path.Search} />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}
