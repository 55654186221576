import { Size } from "../../Util/Constants/Size";
import TextField, { TextFieldType } from "../TextField";
import {
  Field,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { shouldMarkWithError } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import { useContext } from "react";
import MobileViewContext from "../../Navigation/MobileViewContext";
import PhoneField from "./PhoneField";

export interface CustomTextFieldProps {
  field: Field;
  valueObject: any;
  requiredFields?: Field[];
  onChange: (newValue: string) => void;
  fullWidth?: boolean;
  size?: Size;
  error?: boolean;
  disabled?: boolean;
  type?: TextFieldType;
  singelLine?: boolean;
}

export default function CustomTextField(props: CustomTextFieldProps) {
  const value = props.valueObject[props.field];
  const error =
    props.error ||
    shouldMarkWithError(props.field, props.requiredFields, value);
  const [mobile] = useContext(MobileViewContext);

  if (mobile) {
    return <PhoneField field={props.field} valueObject={props.valueObject} />;
  }

  return (
    <TextField
      title={getFieldLabel(props.field)}
      value={value}
      onChange={props.onChange}
      error={error}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      type={props.type}
      size={props.size}
    />
  );
}
