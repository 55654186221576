import OrderPageSideMenu from "./OrderPageSideMenu";
import OrderStatusStepper from "./OrderStatusStepper";
import styles from "./OrderPage.module.scss";
import { useNavigate, useParams } from "react-router";
import { useCallback, useContext, useEffect, useState } from "react";
import Screen from "../../Components/Screen";
import MobileViewContext from "../../Navigation/MobileViewContext";
import {
  OrderField,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { updateObject } from "../../Util/ObjectUpdater";
import PaymentSection from "./Sections/Payment/PaymentSection";
import InvoiceSection from "./Sections/InvoiceSection/InvoiceSection";
import NotesSection from "./Sections/Notes/NotesSection";
import OrganizationSection from "./Sections/OrganizationSection/OrganizationSection";
import CourseLocationDetails from "./Sections/LectureDetailsSections/InternalCourse/CourseLocationDetails";
import LectureTimesTable from "./Sections/LectureDetailsSections/InternalCourse/LectureTimes/LectureTimesTable";
import PublicCourseParticipantsTable from "./Sections/LectureDetailsSections/PublicCourse/PublicCourseParticipantsTable";
import { useDispatch } from "react-redux";
import {
  closeDialog,
  openDialog,
  openSnackbar,
} from "../../Store/Appearance/Actions";
import { calculateOrderStatus } from "./OrderStatus/OrderStatusCalculator";
import {
  getOrderMissingRequiredFields,
  organizationHasMissingFields,
} from "./RequiredFields/OrderMissingFieldsCalculator";
import { Path, getOrderPath } from "../Path";
import OrderOrganizationFieldsDialog from "./RequiredFields/OrderOrganizationFieldsDialog";
import { calculateOrderRequiredFields } from "./RequiredFields/RequiredFieldsByStatus";
import { useVioletApi } from "../../Auth/useFetchWithMsal";
import ContactCard from "./Sections/ContactSection/ContactCard";
import { CustomFlatButton } from "../../Components/CustomComponents/CustomButtons";
import {
  GetOrderResponse,
  HttpResponse,
  Order,
  OrderLecture,
  OrderStatusEnum,
  Organization,
  PublicCourse,
  PublicCourseParticipant,
} from "../../generated/Api";

export type OnOrderChangeFunction = (
  key: OrderField | "publicCourseParticipants",
) => (
  value:
    | string
    | boolean
    | number
    | OrderLecture[]
    | PublicCourseParticipant[]
    | undefined,
) => void;

export default function OrderPage() {
  const { orderId } = useParams();
  const [mobileView] = useContext(MobileViewContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { callApi } = useVioletApi();

  const [order, setOrder] = useState<Order>({} as Order);
  const [orderHasUnsavedChanges, setOrderHasUnsavedChanges] = useState(false);
  const [orderLectures, setOrderLectures] = useState<OrderLecture[]>([]);
  const [publicCourseParticipants, setPublicCourseParticipants] = useState<
    PublicCourseParticipant[]
  >([]);
  const [organization, setOrganization] = useState({} as Organization);
  const [isDuplicateOrganizationId, setIsDuplicateOrganizationId] =
    useState(false);
  const [publicCourse, setPublicCourse] = useState({} as PublicCourse);
  const [orderRequiredFields, setOrderRequiredFields] = useState<OrderField[]>(
    [],
  );
  const [showOrganizationFieldsDialog, setShowOrganizationFieldsDialog] =
    useState(false);

  useEffect(() => {
    const isValidOrderId = orderId && parseInt(orderId);
    if (!isValidOrderId) {
      setOrder({} as Order);
      setOrderLectures([]);
      setPublicCourseParticipants([]);
      setOrganization({} as Organization);
      setPublicCourse({} as PublicCourse);
      return;
    }

    callApi((api) =>
      api.orders
        .getOrder({ id: parseInt(orderId) })
        .then(handleGetOrderResponse),
    );
  }, [orderId, callApi]);

  const handleGetOrderResponse = (
    response?: HttpResponse<GetOrderResponse>,
  ) => {
    if (!response?.data) {
      return;
    }
    setOrder(response.data.order);
    setOrderLectures(response.data.orderLectures);
    setPublicCourseParticipants(response.data.publicCourseParticipants);
    setOrganization(response.data.organization);
    setIsDuplicateOrganizationId(response.data.isDuplicateOrganizationId);
    setPublicCourse(response.data.publicCourse || ({} as PublicCourse));
    setOrderRequiredFields(
      calculateOrderRequiredFields(
        response.data.order.status,
        response.data.organization,
        response.data.orderLectures,
        response.data.order.followUpRequired,
      ).order,
    );
  };

  const updateRequiredFields = useCallback(
    (status: OrderStatusEnum, followUpRequired: boolean) => {
      setOrderRequiredFields(
        calculateOrderRequiredFields(
          status,
          organization,
          orderLectures,
          followUpRequired,
        ).order,
      );
    },
    [setOrderRequiredFields, organization, orderLectures],
  );

  // Listen to changes and update status and required fields.
  useEffect(() => {
    const status = calculateOrderStatus(
      order,
      orderLectures,
      publicCourse,
      publicCourseParticipants,
    );
    if (status !== order.status) {
      setOrder((order) => ({ ...order, status }));
      updateRequiredFields(status, order.followUpRequired);
    }
  }, [
    order,
    orderLectures,
    publicCourseParticipants,
    publicCourse,
    updateRequiredFields,
  ]);

  const onOrderChange: OnOrderChangeFunction = (key) => (value) => {
    setOrder((order) =>
      updateObject(order, {
        [key]: value,
      }),
    );
    setOrderHasUnsavedChanges(true);
    if (key === OrderField.followUpRequired) {
      updateRequiredFields(order.status, value as boolean);
    }
  };

  const onChangePublicCourse = (courseId: number) => {
    setPublicCourseParticipants((participants) =>
      participants.map((participant) => ({
        ...participant,
        lecturesAttending: [],
        participantCost: "",
      })),
    );
    callApi((api) =>
      api.publicCourses
        .getPublicCourse({ publicCourseId: courseId })
        .then((res) => {
          if (res?.data?.publicCourse) {
            onOrderChange(OrderField.publicCourseId)(courseId);
            setPublicCourse(res.data.publicCourse);
          }
        }),
    );
  };

  // This function accepts organization explicitly to handle cases where it was
  // just updated in OrderOrganizationFieldsDialog.
  const onSaveOrder = (org: Organization) => {
    if (organizationHasMissingFields(order.status, org)) {
      setShowOrganizationFieldsDialog(true);
      return;
    }

    const missingFields = getOrderMissingRequiredFields(
      order,
      orderLectures,
      organization,
      publicCourseParticipants,
    );
    if (missingFields.length !== 0) {
      dispatch(
        openDialog(
          "שדות חובה חסרים:",
          missingFields.map(getFieldLabel).join(", "),
        ),
      );
      return;
    }

    const request = {
      order,
      orderLectures,
      publicCourseParticipants,
    };
    callApi((api) =>
      api.orders.updateOrder(request).then((response) => {
        handleGetOrderResponse(response);
        dispatch(
          openSnackbar(`הזמנה מספר ${response.data.order.id} נשמרה בהצלחה`),
        );
        setOrderHasUnsavedChanges(false);
      }),
    );
  };

  const onDuplicateOrder = () => {
    if (orderId) {
      callApi((api) =>
        api.orders
          .duplicateOrder({ orderId: parseInt(orderId) })
          .then((result) => {
            const duplicatedOrder = result.data;
            if (duplicatedOrder) {
              navigate(getOrderPath(duplicatedOrder.id));
            }
          }),
      );
    }
  };

  const onDeleteOrder = () => {
    const onActualDeletOrder = () => {
      dispatch(closeDialog());
      callApi((api) =>
        api.orders.deleteOrder({ orderId: parseInt(orderId!) }).then((res) => {
          if (res.data.success) {
            navigate(Path.AllOrders);
          } else {
            dispatch(openDialog("שגיאה", res.data.errorMessage || ""));
          }
        }),
      );
    };

    dispatch(
      openDialog(
        "מחיקת הזמנה " + orderId,
        "האם אתה בטוח שברצונך למחוק הזמנה זו?\n\nלא ניתן לשחזר הזמנה שנמחקה",
        [
          <CustomFlatButton
            key="ביטול"
            label="ביטול"
            onClick={() => dispatch(closeDialog())}
          />,
          <CustomFlatButton
            key="כן, מחק את ההזמנה"
            label="כן, מחק את ההזמנה"
            onClick={onActualDeletOrder}
          />,
        ],
      ),
    );
  };

  const twoCardsStyle = mobileView ? "" : styles.twoCards;

  return (
    <Screen
      isLoading={!order.id}
      sideMenu={
        <OrderPageSideMenu
          organization={organization}
          order={order}
          orderLectures={orderLectures}
          publicCourse={publicCourse}
          publicCourseParticipants={publicCourseParticipants}
          onOrderChange={onOrderChange}
          onSaveOrder={() => onSaveOrder(organization)}
          onDuplicateOrder={onDuplicateOrder}
          onDeleteOrder={onDeleteOrder}
          requiredFields={orderRequiredFields}
          orderHasUnsavedChanges={orderHasUnsavedChanges}
        />
      }
    >
      <div className={mobileView ? "" : styles.content}>
        {!mobileView && <OrderStatusStepper status={order.status} />}

        <div>
          <div className={twoCardsStyle}>
            <OrganizationSection
              organization={organization}
              isDuplicateOrganizationId={isDuplicateOrganizationId}
            />
            <ContactCard
              idField={OrderField.contactId}
              contactId={order.contactId}
              organization={organization}
              setOrganization={setOrganization}
              onOrderChange={onOrderChange}
              requiredFields={orderRequiredFields}
            />
          </div>

          {!order.isPublicCourseOrder && (
            <CourseLocationDetails
              order={order}
              onOrderChange={onOrderChange}
              requiredFields={orderRequiredFields}
            />
          )}
          {order.isPublicCourseOrder ? (
            <PublicCourseParticipantsTable
              publicCourse={publicCourse}
              onChangePublicCourse={onChangePublicCourse}
              publicCourseParticipants={publicCourseParticipants}
              orderStatus={order.status}
              setPublicCourseParticipants={(publicCourseParticipants) => {
                setPublicCourseParticipants(publicCourseParticipants);
                setOrderHasUnsavedChanges(true);
              }}
            />
          ) : (
            <LectureTimesTable
              orderLectures={orderLectures}
              organization={organization}
              orderStatus={order.status}
              setOrderLectures={(orderLectures) => {
                setOrderLectures(orderLectures);
                setOrderHasUnsavedChanges(true);
              }}
            />
          )}

          <div className={twoCardsStyle}>
            <ContactCard
              idField={OrderField.financialContactId}
              contactId={order.financialContactId}
              organization={organization}
              setOrganization={setOrganization}
              onOrderChange={onOrderChange}
              requiredFields={orderRequiredFields}
            />

            <PaymentSection
              order={order}
              organization={organization}
              onOrderChange={onOrderChange}
              requiredFields={orderRequiredFields}
            />
          </div>

          {!mobileView && (
            <InvoiceSection
              order={order}
              organization={organization}
              onOrderChange={onOrderChange}
              requiredFields={orderRequiredFields}
            />
          )}

          <NotesSection
            order={order}
            onOrderChange={onOrderChange}
            requiredFields={orderRequiredFields}
          />
        </div>
      </div>

      <OrderOrganizationFieldsDialog
        showDialog={showOrganizationFieldsDialog}
        closeDialog={() => setShowOrganizationFieldsDialog(false)}
        organization={organization}
        setInMemoryOrganization={setOrganization}
        onSaveOrder={onSaveOrder}
      />
    </Screen>
  );
}
