import _ from "lodash";
import {
  LectureTimeField,
  OrderField,
  PublicCourseParticipantField,
} from "./FieldNames";

function arrayMerge(objValue: string[], srcValue: string[]) {
  return objValue.concat(srcValue);
}

export interface IRequiredFields {
  order: OrderField[];
  internalOrder: OrderField[];
  internalOrderLectureTime: LectureTimeField[];
  onlineCourseLectureTime: LectureTimeField[];
  publicCourseParticipant: PublicCourseParticipantField[];
}

export function mergerRequiredFields(
  base: IRequiredFields,
  newFields: Partial<IRequiredFields> = {},
): IRequiredFields {
  return _.mergeWith(_.cloneDeep(base), newFields, arrayMerge);
}
