import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../../../../Store/Appearance/Actions";
import { CustomIconButton } from "../../../../../../Components/CustomComponents/CustomButtons";
import LinkIcon from "@mui/icons-material/Link";
import { OrderLecture } from "../../../../../../generated/Api";

export default function CopyOfferLinkButton(props: {
  lectureTime: OrderLecture;
  returnNullIfDisabled: boolean;
}) {
  const dispatch = useDispatch();
  const onCopy = () => {
    const baseUrl =
      process.env.NODE_ENV === "production"
        ? "https://offer-ng.c-point.co.il"
        : "http://localhost:3001";

    const url = `${baseUrl}/#/v1?l=${props.lectureTime.id}&t=${props.lectureTime.offerToken}`;

    navigator.clipboard
      .writeText(url)
      .then(() => dispatch(openSnackbar("הקישור הועתק בהצלחה")));
  };

  const isCopyButtonEnabled =
    props.lectureTime.id &&
    props.lectureTime.offerDate &&
    props.lectureTime.subject &&
    props.lectureTime.content &&
    props.lectureTime.offerCost &&
    props.lectureTime.paymentConditionInfo;

  if (!isCopyButtonEnabled && props.returnNullIfDisabled) {
    return null;
  }

  return (
    <CustomIconButton
      disabled={!isCopyButtonEnabled}
      tooltip={"העתק קישור"}
      onClick={onCopy}
    >
      <LinkIcon />
    </CustomIconButton>
  );
}
