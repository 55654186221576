import {
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
} from "redux";
import thunkMiddleware from "redux-thunk";
import appearance from "./Appearance/Reducer";
import initialData from "./InitialData/Reducer";

const combinedReducers = combineReducers({
  appearance,
  initialData,
});

const store = reduxCreateStore(
  combinedReducers,
  applyMiddleware(thunkMiddleware),
);

export default store;
