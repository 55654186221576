import { APP_VERSION } from "./Navigation/AppMenu";
import { InitialData } from "./generated/Api";

const CACHE_KEY = "VioletDataCache";

// TODO: Make this file a custom hook.
type LocalCache = {
  initialData: InitialData;
  version: string;
  timestamp: number;
};

export async function loadFromCache(
  setData: (initialData: InitialData) => void,
) {
  const startTime = new Date().getTime();
  const cacheData = await readFromCache();
  const shouldUse = shouldUseCache(cacheData);
  if (shouldUse) {
    setData(cacheData!.initialData);
  }
  console.log("c", new Date().getTime() - startTime, shouldUse ? "c" : "x");
}

function shouldUseCache(cacheData?: LocalCache) {
  if (!cacheData) {
    console.log("No cache data");
    return false;
  }

  if (cacheData.version === "dev") {
    console.log("Ignoring cache for dev version");
    return false;
  }

  if (cacheData.version !== APP_VERSION) {
    console.log("Ignoring cache for a different version");
    return false;
  }

  const recent =
    new Date().getTime() - (cacheData.timestamp || 0) <
    14 * 24 * 60 * 60 * 1000;
  if (!recent) {
    console.log("Ignoring cache for old data");
    return false;
  }
  return true;
}

export function setCacheData(initialData: InitialData) {
  const data: LocalCache = {
    initialData,
    version: APP_VERSION,
    timestamp: new Date().getTime(),
  };

  console.log("Setting cache", data);
  localStorage.setItem(CACHE_KEY, JSON.stringify(data));
}

export function clearCacheData() {
  console.log("Clearing cache");
  localStorage.removeItem(CACHE_KEY);
}

async function readFromCache() {
  const cacheData = localStorage.getItem(CACHE_KEY);

  if (!cacheData) {
    console.log("No cache");
    return undefined;
  }

  const res = JSON.parse(cacheData) as LocalCache;
  console.log("Read from cache", res);

  return res;
}

// // Called when items have changed, update cache only if exists already
// export function setItemsInCache(items: IItemsMap) {
//   log("Request to update items cache");
//   readFromCache().then((cacheData) => {
//     if (cacheData) {
//       setCacheData(cacheData.user, items);
//     }
//   });
// }

// // Called when user fields have changed, update cache only if exists already
// export function setUserInCache(user: IUser) {
//   log("Request to update user cache");
//   readFromCache().then((cacheData) => {
//     if (cacheData) {
//       setCacheData(user, cacheData.items);
//     }
//   });
// }
