import {
  Field,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { shouldMarkWithError } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import TernarySelector from "../TernarySelector";

interface CustomTrenarySelectorProps {
  field: Field;
  valueObject: any;
  requiredFields?: Field[];
  onChange: (newValue: boolean | undefined) => void;
  forceErrorColor?: boolean;
}

export default function CustomTrenarySelector(
  props: CustomTrenarySelectorProps,
) {
  const value = props.valueObject[props.field];
  const error =
    (props.forceErrorColor && value === true) ||
    (value === undefined &&
      shouldMarkWithError(props.field, props.requiredFields, value));

  return (
    <TernarySelector
      title={getFieldLabel(props.field)}
      value={value}
      onChange={props.onChange}
      error={error}
    />
  );
}
