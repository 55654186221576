import CustomDialog from "../../../Components/CustomComponents/CustomDialog";
import {
  OrganizationField,
  getFieldLabel,
} from "../../../Store/Appearance/RequiredFields/FieldNames";
import { paymentConditionsSuggestions } from "../../../Util/Constants/PaymentCondition";
import { Size, getFieldWidth } from "../../../Util/Constants/Size";
import { useEffect, useState } from "react";
import TextField from "../../../Components/TextField";
import AutoComplete from "../../../Components/AutoComplete";
import { useSelector } from "react-redux";
import { isDuplicateOrganizationId } from "../../../Store/InitialData/Selectors";
import { CustomRaisedButton } from "../../../Components/CustomComponents/CustomButtons";
import { useVioletApi } from "../../../Auth/useFetchWithMsal";
import { Organization } from "../../../generated/Api";

interface OrderOrganizationFieldsDialogProps {
  showDialog: boolean;
  closeDialog: () => void;
  organization: Organization;
  setInMemoryOrganization: React.Dispatch<React.SetStateAction<Organization>>;
  onSaveOrder: (organization: Organization) => void;
}

export default function OrderOrganizationFieldsDialog(
  props: OrderOrganizationFieldsDialogProps,
) {
  const [companyId, setCompanyId] = useState("");
  const [paymentConditions, setPaymentConditions] = useState("");
  const { callApi } = useVioletApi();

  useEffect(() => {
    setCompanyId(props.organization.companyId || "");
    setPaymentConditions(props.organization.paymentConditions || "");
  }, [props.organization]);

  const isDuplicateCompanyId = useSelector(isDuplicateOrganizationId)(
    companyId,
  );

  const onSave = () => {
    const updatedOrganization: Organization = {
      ...props.organization,
      companyId,
      paymentConditions,
    };
    props.setInMemoryOrganization(updatedOrganization);
    callApi((api) =>
      api.organizations.updateOrganization(props.organization).then(() => {
        props.onSaveOrder(updatedOrganization);
        props.closeDialog();
      }),
    );
  };

  return (
    <CustomDialog
      open={props.showDialog}
      onRequestClose={props.closeDialog}
      title="שדות חסרים בארגון"
      actions={[
        <CustomRaisedButton
          key={"dialog." + props.organization.id}
          label="שמור פרטי ארגון"
          onClick={onSave}
          disabled={!companyId || !paymentConditions}
          variant="contained"
        />,
      ]}
    >
      <TextField
        title={getFieldLabel(OrganizationField.companyId)}
        value={companyId}
        onChange={setCompanyId}
        error={!companyId || isDuplicateCompanyId}
        size={Size.M}
      />
      <AutoComplete
        suggestions={paymentConditionsSuggestions}
        title={getFieldLabel(OrganizationField.paymentConditions)}
        textFieldValue={paymentConditions}
        onTextChange={setPaymentConditions}
        onSuggestionSelected={() => {}}
        error={!paymentConditions}
        width={getFieldWidth(false, Size.XL)}
      />
    </CustomDialog>
  );
}
