import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as _ from "lodash";
import { IStringObject } from "../../Interfaces/IStringObject";
import CustomTableRow from "./CustomTableRow";
import { Field } from "../../Store/Appearance/RequiredFields/FieldNames";
import { TableSortLabel } from "@mui/material";
import { useEffect, useState } from "react";

interface CustomTableProps<TElement extends object> {
  tableHeaders: IStringObject[];
  elements: TElement[];
  onEditButton: (element: TElement) => void;
  onPickButton?: (element: TElement) => void;
  onDeleteButton?: (rowIndex: number) => void;
  requiredFields?: Field[];
  renderCell?: (headerKey: string, element: TElement) => React.ReactNode | null;
}

type Order = "asc" | "desc";

export default function CustomTable<TElement extends object>(
  props: CustomTableProps<TElement>,
) {
  const [orderByKey, setOrderByKey] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [elements, setElements] = useState(props.elements);

  useEffect(() => {
    setElements(props.elements);
    setOrderByKey("");
    setOrder("asc");
  }, [props.elements]);

  useEffect(() => {
    setElements((elements) => _.orderBy(elements, orderByKey, order));
  }, [orderByKey, setElements, order]);

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {_.map(props.tableHeaders, (header, index) => (
            <TableCell key={index}>
              <TableSortLabel
                active={orderByKey === key(header)}
                direction={orderByKey === key(header) ? order : "asc"}
                onClick={() => {
                  if (orderByKey === key(header)) {
                    setOrder((x) => (x === "asc" ? "desc" : "asc"));
                  } else {
                    setOrder("asc");
                    setOrderByKey(key(header));
                  }
                }}
              >
                {value(header)}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {elements.map((element, index) => (
          <CustomTableRow
            key={index + "/" + elements?.length}
            index={index}
            headers={props.tableHeaders}
            element={element}
            onEditButton={props.onEditButton}
            onPickButton={props.onPickButton}
            onDeleteButton={props.onDeleteButton}
            requiredFields={props.requiredFields}
            renderCell={props.renderCell}
          />
        ))}
      </TableBody>
    </Table>
  );
}

function key(stringObject: IStringObject) {
  return Object.keys(stringObject)[0];
}

function value(stringObject: IStringObject) {
  return Object.values(stringObject)[0];
}
