import React from "react";
import * as _ from "lodash";
import CustomTable from "./CustomTable";
import CustomSection from "../CustomComponents/CustomSection";
import { IStringObject } from "../../Interfaces/IStringObject";
import { Field } from "../../Store/Appearance/RequiredFields/FieldNames";

interface CustomPaperTableProps<TElement extends object> {
  title?: string;
  elements: TElement[];
  limit?: number;
  tableHeaders: IStringObject[];
  onEditButton: (element: TElement) => void;
  sectionOptions?: React.ReactNode;
  onDeleteButton?: (rowIndex: number) => void;
  beforeTable?: React.ReactNode;
  requiredFields?: Field[];
  renderCell?: (headerKey: string, element: TElement) => React.ReactNode | null;
}

export default function CustomPaperTable<TElement extends object>(
  props: CustomPaperTableProps<TElement>,
) {
  let elements = props.elements;

  if (props.limit !== undefined && props.limit !== -1) {
    elements = _.slice(elements, 0, props.limit);
  }

  return (
    <CustomSection title={props.title} options={props.sectionOptions}>
      {props.beforeTable}

      <CustomTable
        tableHeaders={props.tableHeaders}
        elements={elements}
        onEditButton={props.onEditButton}
        onDeleteButton={props.onDeleteButton}
        requiredFields={props.requiredFields}
        renderCell={props.renderCell}
      />
    </CustomSection>
  );
}
