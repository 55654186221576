import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import CalculateIcon from "@mui/icons-material/LocalAtm";
import { Size } from "../../../../Util/Constants/Size";
import { CustomFlatButton } from "../../../../Components/CustomComponents/CustomButtons";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import {
  Field,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { OnOrderChangeFunction } from "../../OrderPage";
import calculateSum from "./CalculateSum";
import { Order, Organization } from "../../../../generated/Api";

interface PaymentSectionProps {
  order: Order;
  organization: Organization;
  onOrderChange: OnOrderChangeFunction;
  requiredFields: Field[];
}

export default function PaymentSection(props: PaymentSectionProps) {
  return (
    <CustomSection
      title="תשלום"
      options={[
        <CustomFlatButton
          key={"payment." + props.order.id}
          label="חשב סכום לתשלום"
          onClick={() => calculateSum(props.order, props.onOrderChange)}
          icon={<CalculateIcon />}
        />,
      ]}
    >
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.cost}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.oneWayDistance}
        size={Size.M}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.travelExpenses}
        size={Size.M}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.extraCosts}
        size={Size.M}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.sum}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.vat}
        size={Size.M}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.totalSum}
      />
    </CustomSection>
  );
}
