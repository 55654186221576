import * as _ from "lodash";
import {
  terminatingStatuses,
  progressiveStatuses,
} from "../../../Interfaces/Status";
import { hasDatePassed } from "../../../Util/TimeUtil";
import { existsAndNotEmpty } from "./OrderStatusUtils";
import { OrderField } from "../../../Store/Appearance/RequiredFields/FieldNames";
import {
  Order,
  OrderLecture,
  OrderStatusEnum,
  PublicCourse,
  PublicCourseParticipant,
} from "../../../generated/Api";

export function calculateOrderStatus(
  order: Order,
  orderLectures: OrderLecture[],
  publicCourse: PublicCourse | undefined,
  publicCourseParticipants: PublicCourseParticipant[],
) {
  for (let statusIndex in terminatingStatuses) {
    const status = terminatingStatuses[statusIndex];
    if (
      meetsRequirements(
        order,
        orderLectures,
        publicCourse,
        publicCourseParticipants,
        status,
      )
    ) {
      return status;
    }
  }

  let result = OrderStatusEnum.Offer;

  for (let statusIndex in progressiveStatuses) {
    const status = progressiveStatuses[statusIndex];
    if (
      meetsRequirements(
        order,
        orderLectures,
        publicCourse,
        publicCourseParticipants,
        status,
      )
    ) {
      result = status;
    } else {
      break;
    }
  }

  return result;
}

function meetsRequirements(
  order: Order,
  orderLectures: OrderLecture[],
  publicCourse: PublicCourse | undefined,
  publicCourseParticipants: PublicCourseParticipant[],
  status: OrderStatusEnum,
) {
  // This is here to make sure the an order won't be in a pre-approved
  // status when the Approved checkbox is checked.
  if (status === OrderStatusEnum.Offer && order.approved) {
    return true;
  }

  switch (status) {
    case OrderStatusEnum.Offer:
      return isOffer();

    case OrderStatusEnum.ApprovedOrder:
      return isApprovedOrder(order);

    case OrderStatusEnum.IsExecuting:
      return isExecuting(
        order,
        orderLectures,
        publicCourse,
        publicCourseParticipants,
      );

    case OrderStatusEnum.Executed:
      return isExecuted(
        order,
        orderLectures,
        publicCourse,
        publicCourseParticipants,
      );

    case OrderStatusEnum.WaitingPayment:
      return isWaitingPayment(order);

    case OrderStatusEnum.Payed:
      return isPayed(order);

    case OrderStatusEnum.Cancelled:
      return isCancelled(order);

    case OrderStatusEnum.Rejected:
      return isRejected(order);

    default:
      console.warn("Unexpected status:", status);
      return false;
  }
}

function isOffer() {
  // An order is always a contact
  return true;
}

function isApprovedOrder(order: Order) {
  // Order must be approved
  return order.approved;
}

function isExecuting(
  order: Order,
  orderLectures: OrderLecture[],
  publicCourse: PublicCourse | undefined,
  publicCourseParticipants: PublicCourseParticipant[],
) {
  if (isWaitingPayment(order)) {
    return true;
  }
  // At least one lectures is done
  return _.some(
    getDatesToCheck(
      order,
      orderLectures,
      publicCourse,
      publicCourseParticipants,
    ),
    hasDatePassed,
  );
}

function isExecuted(
  order: Order,
  orderLectures: OrderLecture[],
  publicCourse: PublicCourse | undefined,
  publicCourseParticipants: PublicCourseParticipant[],
) {
  if (isWaitingPayment(order)) {
    return true;
  }
  // All lectures passed
  return _.every(
    getDatesToCheck(
      order,
      orderLectures,
      publicCourse,
      publicCourseParticipants,
    ),
    hasDatePassed,
  );
}

function isWaitingPayment(order: Order) {
  // Order has proforma or tax invoice number
  return (
    existsAndNotEmpty(order, OrderField.proformaInvoiceNumber) ||
    existsAndNotEmpty(order, OrderField.taxInvoiceNumber)
  );
}

function isPayed(order: Order) {
  return existsAndNotEmpty(order, OrderField.receiptNumber);
}

function isCancelled(order: Order) {
  return order.cancelled;
}

function isRejected(order: Order) {
  return order.rejected;
}

function getDatesToCheck(
  order: Order,
  orderLectures: OrderLecture[],
  publicCourse: PublicCourse | undefined,
  publicCourseParticipants: PublicCourseParticipant[],
) {
  if (order.isPublicCourseOrder && publicCourse) {
    const lecturesThatAtLeastOneParticipantIsAttending = new Set(
      _.flatMap(publicCourseParticipants, (participant) =>
        participant.lecturesAttending.map((x) => x.publicCourseLectureId),
      ),
    );

    return _.chain(publicCourse.publicCourseLectures)
      .filter((lecture) =>
        lecturesThatAtLeastOneParticipantIsAttending.has(lecture.id),
      )
      .map((lecture) => lecture.date)
      .value();
  } else {
    return _.map(orderLectures, (lectureTime) => lectureTime.date);
  }
}
