import * as _ from "lodash";
import { Order, OrderStatusEnum } from "../generated/Api";

export const terminatingStatuses = [
  OrderStatusEnum.Cancelled,
  OrderStatusEnum.Rejected,
];

export const progressiveStatuses = [
  OrderStatusEnum.Offer,
  OrderStatusEnum.ApprovedOrder,
  OrderStatusEnum.IsExecuting,
  OrderStatusEnum.Executed,
  OrderStatusEnum.WaitingPayment,
  OrderStatusEnum.Payed,
];

export function getStatusLabels() {
  return {
    offer: "הצעת מחיר",
    approvedOrder: "הזמנה מאושרת",
    isExecuting: "בביצוע",
    executed: "בוצע",
    waitingPayment: "ממתין לתשלום",
    payed: "שולם",
    cancelled: "בוטל",
    rejected: "לא אושר",
    followUp: " + המשך טיפול",
  };
}

export function getOrderStatusLabel(order: Order) {
  const labels = getStatusLabels();
  if (_.isEmpty(order)) return labels[OrderStatusEnum.Offer];

  let status = labels[order.status];
  if (order.followUpRequired) status += labels.followUp;
  return status;
}

export function getStatusLabel(status: OrderStatusEnum) {
  if (!status) return getStatusLabels().offer;

  return getStatusLabels()[status];
}

export function getStatusOptions() {
  const statusObjects = _.map(getStatusLabels(), (label, status) => {
    return {
      key: status,
      label,
    };
  });

  return _.dropRight(statusObjects);
}
