import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import {
  getFieldLabel,
  OrganizationField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import styles from "./OrganizationSection.module.css";
import { useNavigate } from "react-router";
import { getOrganizationPath } from "../../../Path";
import { CustomFlatButton } from "../../../../Components/CustomComponents/CustomButtons";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ContactIconField from "../../../../Components/CardIconField";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import PaymentIcon from "@mui/icons-material/Payment";
import NoteIcon from "@mui/icons-material/Note";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useContext } from "react";
import MobileViewContext from "../../../../Navigation/MobileViewContext";
import { Organization } from "../../../../generated/Api";

interface OrganizationSectionProps {
  organization: Organization;
  isDuplicateOrganizationId: boolean;
}

export default function OrganizationSection(props: OrganizationSectionProps) {
  const org = props.organization;
  const navigate = useNavigate();
  const [mobileView] = useContext(MobileViewContext);

  let address = "";
  if (org.address) {
    address += org.address;
  }
  if (org.city) {
    address += ", " + org.city;
  }
  if (org.postalCode) {
    address += ", " + org.postalCode;
  }
  return (
    <CustomSection
      title="פרטי הארגון"
      isLoading={!org.id}
      options={
        <CustomFlatButton
          onClick={() => navigate(getOrganizationPath(org.id))}
          label="מעבר לארגון"
          icon={<KeyboardDoubleArrowLeftIcon />}
        />
      }
    >
      <div className={mobileView ? "" : styles.organizationSection}>
        <ContactIconField value={org.name} icon={<BusinessIcon />} />
        <ContactIconField value={address} icon={<HomeIcon />} />
        <ContactIconField value={org.companyId} icon={<WorkIcon />} />
        <ContactIconField
          value={org.paymentConditions}
          icon={<PaymentIcon />}
        />
        <ContactIconField
          value={
            org.internalOrderIdRequired
              ? getFieldLabel(OrganizationField.internalOrderIdRequired)
              : ""
          }
          icon={<AssignmentIcon />}
        />
        <ContactIconField value={org.notes} icon={<NoteIcon />} />
      </div>
    </CustomSection>
  );
}
