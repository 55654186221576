import CustomCheckbox from "../../../../Components/CustomComponents/CustomCheckbox";
import styles from "./CourseLecturesInstance.module.css";
import { PublicCourseLectureField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import CustomTextField from "../../../../Components/CustomComponents/CustomTextField";
import { PublicCourseLecture } from "../../../../generated/Api";

interface CourseLecturesInstanceProps {
  publicCourseLecture: PublicCourseLecture;
  lectureId: number;
  onFieldChange: (
    key: PublicCourseLectureField,
  ) => (newValue: string | boolean) => void;
  requiredFields: PublicCourseLectureField[];
}

export default function GuestLecturerFields(
  props: CourseLecturesInstanceProps,
) {
  if (!props.publicCourseLecture.guestLecturer) {
    return (
      <CustomCheckbox
        field={PublicCourseLectureField.guestLecturer}
        valueObject={props.publicCourseLecture}
        onChange={props.onFieldChange(PublicCourseLectureField.guestLecturer)}
      />
    );
  }
  return (
    <div className={styles.fields}>
      <CustomCheckbox
        field={PublicCourseLectureField.guestLecturer}
        valueObject={props.publicCourseLecture}
        onChange={props.onFieldChange(PublicCourseLectureField.guestLecturer)}
        requiredFields={props.requiredFields}
      />
      <CustomTextField
        field={PublicCourseLectureField.guestLecturerName}
        valueObject={props.publicCourseLecture}
        onChange={props.onFieldChange(
          PublicCourseLectureField.guestLecturerName,
        )}
        requiredFields={props.requiredFields}
      />
      <CustomTextField
        field={PublicCourseLectureField.guestLecturerCost}
        valueObject={props.publicCourseLecture}
        onChange={props.onFieldChange(
          PublicCourseLectureField.guestLecturerCost,
        )}
        requiredFields={props.requiredFields}
      />
    </div>
  );
}
