import { Size } from "../../../../../../Util/Constants/Size";
import { toSuggestions } from "../../../../../../Components/AutoComplete";
import CustomAutoComplete from "../../../../../../Components/CustomComponents/CustomAutoComplete";
import PreparationTimes from "./PreparationTimes";
import OfferApprovalInformation from "./OfferApprovalInformation";
import { useSelector } from "react-redux";
import { getOfferedLectures } from "../../../../../../Store/InitialData/Selectors";
import styles from "./SingleLectureTime.module.css";
import { LectureTimeField } from "../../../../../../Store/Appearance/RequiredFields/FieldNames";
import CustomDatePicker from "../../../../../../Components/CustomComponents/CustomDatePicker";
import CustomTextField from "../../../../../../Components/CustomComponents/CustomTextField";
import { TIES } from "../../../../../../Util/Constants/Ties";
import CustomToggle from "../../../../../../Components/CustomComponents/CustomToggle";
import OfferDetails from "./OfferDetails";
import CustomTimePicker from "../../../../../../Components/CustomComponents/CustomTimePicker";
import { OrderLecture, Organization } from "../../../../../../generated/Api";

interface SingleLectureTimeProps {
  lectureTimeIndex: number;
  lectureTime: OrderLecture;
  onChangeField: (key: LectureTimeField) => (value: string | boolean) => void;
  requiredFields: LectureTimeField[];
  organization: Organization;
  removeOfferApproval: (lectureId: number) => void;
}

export default function SingleLectureTime(props: SingleLectureTimeProps) {
  const lectureTimeIndex = props.lectureTimeIndex;
  const lectureTime = props.lectureTime;
  const isOnline = lectureTime.isOnline;

  const offeredLectures = toSuggestions(useSelector(getOfferedLectures));
  const ties = toSuggestions(TIES);

  return (
    <div>
      <CustomToggle
        field={LectureTimeField.isOnline}
        valueObject={lectureTime}
        onChange={props.onChangeField(LectureTimeField.isOnline)}
        requiredFields={props.requiredFields}
      />
      <div className={styles.lectureTimeFields}>
        <CustomDatePicker
          field={LectureTimeField.date}
          valueObject={lectureTime}
          onChange={props.onChangeField(LectureTimeField.date)}
          requiredFields={props.requiredFields}
        />
        <CustomAutoComplete
          field={LectureTimeField.topic}
          valueObject={lectureTime}
          onTextChange={props.onChangeField(LectureTimeField.topic)}
          suggestions={offeredLectures}
          requiredFields={props.requiredFields}
          size={Size.X2L}
        />
        <CustomTimePicker
          field={LectureTimeField.startTime}
          valueObject={lectureTime}
          onChange={props.onChangeField(LectureTimeField.startTime)}
          requiredFields={props.requiredFields}
        />
        <CustomTimePicker
          field={LectureTimeField.endTime}
          valueObject={lectureTime}
          onChange={props.onChangeField(LectureTimeField.endTime)}
          requiredFields={props.requiredFields}
        />
        <CustomTextField
          field={LectureTimeField.duration}
          valueObject={lectureTime}
          onChange={props.onChangeField(LectureTimeField.duration)}
          requiredFields={props.requiredFields}
          size={Size.S}
        />
        <CustomTextField
          field={LectureTimeField.audienceSize}
          valueObject={lectureTime}
          onChange={props.onChangeField(LectureTimeField.audienceSize)}
          requiredFields={props.requiredFields}
          size={Size.S}
        />
        <CustomAutoComplete
          field={LectureTimeField.tie}
          valueObject={lectureTime}
          suggestions={ties}
          onTextChange={props.onChangeField(LectureTimeField.tie)}
          requiredFields={props.requiredFields}
          size={Size.M}
        />
        {!isOnline && (
          <CustomTimePicker
            field={LectureTimeField.travelTime}
            valueObject={lectureTime}
            onChange={props.onChangeField(LectureTimeField.travelTime)}
            requiredFields={props.requiredFields}
          />
        )}
      </div>

      {isOnline && (
        <div className={styles.videoLink}>
          <CustomTextField
            field={LectureTimeField.videoCallLink}
            valueObject={lectureTime}
            onChange={props.onChangeField(LectureTimeField.videoCallLink)}
            requiredFields={props.requiredFields}
            fullWidth
            singelLine
          />
        </div>
      )}

      {!isOnline && !!lectureTime.travelTime && (
        <PreparationTimes lectureTime={lectureTime} />
      )}

      <OfferApprovalInformation
        lectureTimeIndex={lectureTimeIndex}
        lectureTime={lectureTime}
        removeOfferApproval={props.removeOfferApproval}
      />
      <OfferDetails
        lectureTime={lectureTime}
        onChangeField={props.onChangeField}
        paymentConditions={props.organization.paymentConditions}
      />
    </div>
  );
}
