import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import {
  Field,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import { OnOrderChangeFunction } from "../../OrderPage";
import { Order } from "../../../../generated/Api";

export default function NotesSection(props: {
  order: Order;
  onOrderChange: OnOrderChangeFunction;
  requiredFields: Field[];
}) {
  return (
    <CustomSection title="פרטים נוספים">
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.notes}
        fullWidth
      />
    </CustomSection>
  );
}
