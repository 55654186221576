import { Size } from "../../../../Util/Constants/Size";
import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import OrderCheckbox from "../ConnectedCustomComponents/OrderCheckbox";
import {
  Field,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import OrderDatePicker from "../ConnectedCustomComponents/OrderDatePicker";
import { OnOrderChangeFunction } from "../../OrderPage";
import CustomDatePicker from "../../../../Components/CustomComponents/CustomDatePicker";
import calculatePayDate from "./ProformaInvoiceDateCalculator";
import { Order, Organization } from "../../../../generated/Api";

export default function InvoiceSection(props: {
  order: Order;
  organization: Organization;
  onOrderChange: OnOrderChangeFunction;
  requiredFields: Field[];
}) {
  return (
    <CustomSection title="חשבונאות">
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.proformaInvoiceNumber}
        size={Size.M}
      />
      <CustomDatePicker
        field={OrderField.proformaInvoiceDate}
        valueObject={props.order}
        onChange={(value: string) => {
          props.onOrderChange(OrderField.proformaInvoiceDate)(value);

          const payDate = calculatePayDate(
            value,
            props.organization.paymentConditions,
          );
          if (payDate !== null) {
            props.onOrderChange(OrderField.expectedPayDate)(payDate);
          }
        }}
      />
      <OrderDatePicker
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.expectedPayDate}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.internalOrderNumber}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.receiptNumber}
        size={Size.M}
      />
      <OrderDatePicker
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.taxInvoiceDate}
      />
      <OrderTextField
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.taxInvoiceNumber}
        size={Size.M}
      />
      <OrderCheckbox
        order={props.order}
        onOrderChange={props.onOrderChange}
        requiredFields={props.requiredFields}
        field={OrderField.externalInvoiceSent}
      />
    </CustomSection>
  );
}
