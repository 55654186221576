export enum Size {
  S,
  M,
  L,
  XL,
  X2L,
  X3L,

  DATE,
  TIME,
}

export function getFieldWidth(fullWidth?: boolean, size?: Size) {
  if (fullWidth) return "100%";

  switch (size) {
    case Size.S:
      return 50;

    case Size.M:
      return 100;

    case Size.L:
    default:
      return 150;

    case Size.XL:
      return 200;

    case Size.X2L:
      return 250;

    case Size.X3L:
      return 300;

    case Size.DATE:
      return 120;
    case Size.TIME:
      return 90;
  }
}
